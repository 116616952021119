const initialState = {
  name: '',
  state: 'new', // new/connecting/connected/disconnected/closed,
  locked: false,
  inLobby: false,
  signInRequired: false,
  accessCode: '', // access code to the room if locked and joinByAccessCode == true
  joinByAccessCode: true, // if true: accessCode is a possibility to open the room
  activeSpeakerId: null,
  torrentSupport: false,
  showSettings: false,
  fullScreenConsumer: null, // ConsumerID
  windowConsumer: null, // ConsumerID
  toolbarsVisible: true,
  activeSpeaker: null,
  peerName: null,
  mode: 'democratic',
  selectedPeerId: null,
  spotlights: [],
  settingsOpen: false,
  recordingOptions: false,
  recordingType: '', // ["", "DESKTOP", "CLOUD"]
  lockDialogOpen: false,
  joined: false,
  destroyTimer: 0,
  isWhiteboardOpen: false,
  isInitiator: false,
  isRecording: false,
  fullScreen: false,
  joiningRoom: false,
  isShowHideOpen: true,
  recordingActionText: '',
  callTimer: '00:00',
  socketState: "",
  iceCandidatesState: "",
  roomNotStarted: false,
};

const room = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROOM_NAME': {
      const { name } = action.payload;

      return { ...state, name };
    }

    case 'SET_ROOM_STATE': {
      const roomState = action.payload.state;

      if (roomState === 'connected') return { ...state, state: roomState };
      else return { ...state, state: roomState, activeSpeakerId: null };
    }

    case 'SET_SOCKET_STATE': {
      const socketState = action.payload.state;

      return { ...state, socketState: socketState };
    }

    case 'SET_ICE_CANDIDATE_STATE': {
      const iceCandidatesState = action.payload.state;

      return { ...state, iceCandidatesState: iceCandidatesState };
    }

    case 'SET_ROOM_LOCKED': {
      return { ...state, locked: true };
    }

    case 'SET_ROOM_UNLOCKED': {
      return { ...state, locked: false };
    }

    case 'SET_IN_LOBBY': {
      const { inLobby } = action.payload;

      return { ...state, inLobby };
    }

    case 'SET_SIGN_IN_REQUIRED': {
      const { signInRequired } = action.payload;

      return { ...state, signInRequired };
    }

    case 'SET_ACCESS_CODE': {
      const { accessCode } = action.payload;

      return { ...state, accessCode };
    }

    case 'SET_JOIN_BY_ACCESS_CODE': {
      const { joinByAccessCode } = action.payload;

      return { ...state, joinByAccessCode };
    }

    case 'SET_LOCK_DIALOG_OPEN': {
      const { lockDialogOpen } = action.payload;

      return { ...state, lockDialogOpen };
    }

    case 'SET_SETTINGS_OPEN': {
      const { settingsOpen } = action.payload;

      return { ...state, settingsOpen };
    }

    case 'SET_RECORDING_OPTIONS': {
      const { recordingOptions } = action.payload;
      return { ...state, recordingOptions };
    }

    case 'SET_DESKTOP_RECORDING': {
      const { recordingType } = action.payload;
      return { ...state, recordingType };
    }

    case 'SET_RECORDING_ACTION_TEXT': {
      const { recordingActionText } = action.payload;
      return { ...state, recordingActionText };
    }
    
    case 'SET_ROOM_ACTIVE_SPEAKER': {
      const { peerId } = action.payload;

      return { ...state, activeSpeakerId: peerId };
    }

    case 'FILE_SHARING_SUPPORTED': {
      const { supported } = action.payload;

      return { ...state, torrentSupport: supported };
    }

    case 'TOGGLE_JOINED': {
      const joined = true;

      return { ...state, joined };
    }

    case 'TOGGLE_FULLSCREEN_CONSUMER': {
      const { consumerId } = action.payload;
      const currentConsumer = state.fullScreenConsumer;

      return {
        ...state,
        fullScreenConsumer: currentConsumer ? null : consumerId,
      };
    }

    case 'TOGGLE_WINDOW_CONSUMER': {
      const { consumerId } = action.payload;
      const currentConsumer = state.windowConsumer;

      if (currentConsumer === consumerId)
        return { ...state, windowConsumer: null };
      else return { ...state, windowConsumer: consumerId };
    }

    case 'SET_TOOLBARS_VISIBLE': {
      const { toolbarsVisible } = action.payload;

      return { ...state, toolbarsVisible };
    }

    case 'SET_DISPLAY_MODE':
      return { ...state, mode: action.payload.mode };

    case 'SET_SELECTED_PEER': {
      const { selectedPeerId } = action.payload;

      return {
        ...state,

        selectedPeerId:
          state.selectedPeerId === selectedPeerId ? null : selectedPeerId,
      };
    }

    case 'SET_SPOTLIGHTS': {
      const { spotlights } = action.payload;

      return { ...state, spotlights };
    }

    case 'SET_DESTROY_TIMER':
      return { ...state, destroyTimer: action.payload };

    case 'SET_SHOW_WHITEBOARD':
      return { ...state, isWhiteboardOpen: true };

   
    case 'SET_HIDE_WHITEBOARD':
      return { ...state, isWhiteboardOpen: false, isInitiator: false };

    case 'SET_WHITEBOARD_INITIATOR':
      return { ...state, isInitiator: action.initiatorStatus || false };

    case 'SET_FULLSCREEN': {
      const { fullScreen } = action.payload;
      return { ...state, fullScreen };
    }

    case 'SET_WINDOW_CONSUMER':
      return { ...state, windowConsumer: null };

    case 'SET_JOINING_ROOM': {
        return { ...state, joiningRoom:action.payload };
      }

    case 'RE_INITIALIZED_ROOM':
      {
        return { ...initialState  };
      }

    case 'SET_ACTIVE_SPEAKER':
      return { ...state, activeSpeaker: action.payload };
  
    case 'SET_PEER_NAME':
      return { ...state, peerName: action.payload };
      
    case 'SET_SHOW_HIDE_OPEN':
      return { ...state, isShowHideOpen: false };

    case 'SET_CALL_TIMER':
      return { ...state, callTimer: action.payload}

    case 'SET_IS_ROOM_CREATED':
        return { ...state, roomNotStarted: action.payload};

    default:
      return state;
  }
};

export default room;
