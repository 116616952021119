import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footerspacing: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  footerDescription: {
    paddingTop: theme.spacing(2.5),
    color: theme.common.gary_200,
    lineHeight: "29px",
    fontFamily: theme.typography.fontFamily.regular,
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "22px",
    },
  },
  respFooter: {
    width: "100%",
    "&>div": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  },
  footerWrapper: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
      display: "flex",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
  footerHeading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "26px",
    },
  },
  footerContent: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  RespFooter:{
    [theme.breakpoints.down("sm")]: {
     padding:"0 !important",
        },
  },
  logo:{
    width:155,
  }
}));

export default useStyles;
