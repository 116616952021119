import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  useMediaQuery,
  Hidden,
  useTheme,
  Typography,
  Container,
  withWidth,
} from "@material-ui/core";
import useStyles from "./HeaderStyles";
import FooterMenu from "../Footer/FooterMenu";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../../../store";
import MenuIcon from "@material-ui/icons/Menu";
import { logOutHandler, truncateString } from "../../../utils/utils";
import ReportIssue from "../../ReportIssue/ReportIssue";
import logo from "../../../images/talkroom_blue_logo.webp";
import AvatarView from "../../AvatarView/AvatarView";
import { useHistory } from "react-router-dom";
import HeaderUtility from "./HeaderUtility";
import { getFirstName } from "../../../utils";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Header = ({ handleClickOpenPopup, specificContentRef, width }) => {
  HeaderUtility();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const currentRoute = location.pathname;
  const roomId =
    useSelector((state) => state?.settings?.userData?.roomId) || "";

  const aboutLink = [
    {
      name: "About us",
      linkUrl: "/about-us",
    },
    {
      name: "How it works",
      linkUrl: `/${roomId}`,
    },
  ];
  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const theme = useTheme();

  const isLargeScreen = window.innerWidth < 800;
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const settings = useSelector((state) => state.settings?.userData);
  const displayName = `${settings?.firstName} ${settings?.lastName || ""}`;
  const userName = isLargeScreen
    ? truncateString(displayName, 4)
    : truncateString(displayName, 12);
  const isLoggedIn = !!store.getState()?.settings?.userData?.token || false;

  const handleAvatarClick = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAvatarAnchorEl(null);
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    action();
    handleClose();
  };

  const [state, setState] = useState(
    localStorage.getItem("howItWorksClicked") || ""
  );

  let stateLocal;
  const handleHowItWorksClick = () => {
    localStorage.setItem("howItWorksClicked", "1");
    setState(1);
  };

  stateLocal = localStorage.getItem("howItWorksClicked", "2");
  useEffect(() => {
    if (
      specificContentRef &&
      specificContentRef.current &&
      stateLocal === "1"
    ) {
      setTimeout(() => {
        specificContentRef.current.scrollIntoView({ behavior: "smooth" });
      }, 500);

      localStorage.setItem("howItWorksClicked", "2");
      setState("2");
    }
  }, [currentRoute, specificContentRef, state, stateLocal]);

  const handleLogOut = () => {
    logOutHandler();
    history.push(`/`);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.headerParent}
    >
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Link
              to={`/${roomId}`}
              style={{ pointerEvents: currentRoute === "/" ? "none" : "auto" }}
            >
              <Box className={classes.logo}>
                <img src={logo} alt="Logo" width="100%" />
              </Box>
            </Link>
          </Grid>
          <Hidden xsDown>
            <Grid item>
              <Box className={classes.headerMenu}>
                <FooterMenu
                  linkNames={aboutLink}
                  specificContentRef={specificContentRef}
                />
              </Box>
            </Grid>
          </Hidden>
          {!isLoggedIn && (
            <>
              {currentRoute !== "/forget-password" && (
                <Grid item className={classes.btnGrid}>
                  <Box>
                    <Button
                      variant="text"
                      color="primary"
                      className={`${classes.signInBtn}`}
                      onClick={() => handleClickOpenPopup("login", "Sign in")}
                    >
                      Sign in
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={`${classes.registerBtn}`}
                      onClick={() =>
                        handleClickOpenPopup("register", "Create your account")
                      }
                    >
                      {!smallScreen ? "Register Now" : "Register"}
                    </Button>
                  </Box>
                </Grid>
              )}
            </>
          )}
          {isLoggedIn && (
            <Grid item>
              <Hidden>
                <Box className={classes.profileIcon}>
                  <Button
                    onClick={handleAvatarClick}
                    className={classes.avatarBtn}
                  >
                    <AvatarView
                      className={settings?.avatar ? "" : classes.avtar}
                      isName={true}
                      profileUrl={settings?.avatar}
                      nameInitial={displayName?.trimStart()}
                    />
                    <Typography
                      className={classes.profileText}
                      variant={
                        width === "sm" || width === "xs" ? "body1" : "h6"
                      }
                    >
                      {!smallScreen ? userName : getFirstName(userName)}
                    </Typography>
                    <ExpandMoreIcon />
                  </Button>
                  <Menu
                    anchorEl={avatarAnchorEl}
                    open={Boolean(avatarAnchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    getContentAnchorEl={null}
                  >
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick(() =>
                          history.push("/update-profile")
                        )
                      }
                    >
                      <ListItemText>
                        <span className={classes.headerLinks}>My Profile</span>
                      </ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick(() =>
                          history.push("/change-password")
                        )
                      }
                    >
                      <ListItemText>
                        <span className={classes.headerLinks}>
                          Change Password
                        </span>
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick(handleLogOut)}>
                      <ListItemText>
                        <span className={classes.headerLinks}>Sign Out</span>
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </Box>
              </Hidden>
            </Grid>
          )}

          <Hidden smUp>
            <Grid item sm={0} md={2} xs={2}>
              <Box textAlign="right">
                <Button
                  onClick={handleMenuClick}
                  style={{ textDecoration: "none", color: "#333" }}
                  className={classes.hamburgerBtn}
                >
                  <MenuIcon fontSize="medium" />
                </Button>
                <Menu
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  getContentAnchorEl={null}
                >
                  <MenuItem>
                    <ReportIssue showContact={true} />
                  </MenuItem>
                  <Link to="/about-us">
                    <MenuItem>
                      <ListItemText>
                        <span className={classes.hamburgerLinks}>
                          About us{" "}
                        </span>
                      </ListItemText>
                    </MenuItem>
                  </Link>

                  <Link to={`/${roomId}`}>
                    <MenuItem
                      onClick={() => {
                        handleHowItWorksClick();
                        setMenuAnchorEl(null);
                      }}
                    >
                      <ListItemText>
                        <span className={classes.hamburgerLinks}>
                          {" "}
                          How it works
                        </span>
                      </ListItemText>
                    </MenuItem>
                  </Link>
                </Menu>
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Box>
  );
};

export default withWidth()(Header);
