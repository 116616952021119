import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";
export const API_HOST =
  process.env.REACT_APP_SERVER_URL.charAt(
    process.env.REACT_APP_SERVER_URL.length - 1
  ) === "/"
    ? `${process.env.REACT_APP_SERVER_URL || ""}app/v1/`
    : `${process.env.REACT_APP_SERVER_URL || ""}/app/v1/`;

export const CONFIRMATION_DIALOG_MESSAGES = {
  CLEAR_WHITEBOARD: {
    title: "Clear whiteboard",
    content: "Are you sure, you want to clear the whiteboard?",
    confirmText: "Yes",
    cancelText: "No",
  },
  CLOSE_WHITEBOARD: {
    title: "Close whiteboard",
    content: "Are you sure, you want to close the whiteboard?",
    confirmText: "Yes",
    cancelText: "No",
  },
  LEAVE_CALL: {
    title: "Leave meeting",
    content: "Are you sure, you want to leave the meeting?",
    confirmText: "Yes",
    cancelText: "No",
  },
  SESSION_DESTROY: {
    title: "Call Disconnecting",
    content:
      "Call is disconnecting, please click the below button to continue the call.",
    confirmText: "Yes",
  },
  SCREEN_SHARE_NOT_SUPPORTED: {
    title: "Alert!!!",
    content: "Your browser does not support screen sharing.",
    cancelText: "Ok",
  },
  SCREEN_ALREADY_SHARED: {
    title: "Alert!!!",
    content:
      "Screen sharing is currently in use. If you want to share, the other users have to stop sharing.",
    cancelText: "Ok",
  },
};

export const FILE_SIZE = 20; // In MB
export const ALLOWED_IMAGES = ["jpg", "jpeg", "png"];
export const ATTACHMENT_NAME_LENGTH = 10;

// production key
export const RE_CAPTCHA_SECRET_KEY = "6LduG58jAAAAAEqjn6ZExsT_fhdB0_x31QWVFyEm";
// local dev key
// export const RE_CAPTCHA_SECRET_KEY= '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

// Google clientId
export const GOOGLE_CLIENT_ID = "894977369362-altk03g9ndptthjoqvmfqod30qe3pqps.apps.googleusercontent.com";
// Facebook appId
export const FACEBOOK_APP_ID = "";

export const AWS_REQUEST_TIMEOUT = 10 * 60 * 1000; // 10 minutes

export const AWS_VIDEO_UPLOAD_DIRECTORY_PATH = `howToUse-video/how-to-use-feature-v2.mp4`;
export const UPLOAD_FILE_SIZE = 50; // In MB

export const ALLOWED_FILES = [
  "jpg",
  "jpeg",
  "png",
  "svg",
  "gif",
  "tiff",
  "xlsx",
  "xls",
  "pdf",
  "doc",
  "docx",
  "zip",
  "mp4",
  "mp3",
  "3gp",
  "ogg",
  "webm",
  "txt",
  "webp",
  "mov",
  "apk",
  "exe",
  "AppImage",
];
export const REDIRECT_LINKS = {
  FAQ_LINK: "faq",
  ABOUT_US_LINK: "about-us",
  PRIVACY_POLICY_LINK: "privacy-policy",
};

export const TOOLTIP_LABEL = {
  FAQ_LABEL: "FAQ",
  ABOUT_US_LABEL: "About us",
  PRIVACY_POLICY_LABEL: "Privacy policy",
};

export const IMG_ICON = {
  FAQ_ICON: (
    <img src="images/FAQ_blue.png" alt="FAQ" style={{ height: "20px" }} />
  ),
  ABOUT_US_ICON: (
    <InfoOutlinedIcon style={{ fontSize: "1.2285714285714284rem" }} />
  ),
  PRIVACY_POLICY_LABEL_ICON: (
    <img src="images/insurance.png" alt="Privacy policy" />
  ),
  PRIVACY_POLICY_TOPBAR_ICON: (
    <img
      src="images/insurance-black.png"
      alt="Privacy policy"
      style={{ height: "20px", filter: "contrast(35%)" }}
    />
  ),
};

export const ALERT_SOUND_TYPE = {
  NOTIFICATION: "notification",
  RECORD_STARTED: "recordStarted",
  RECORD_IN_PROGRESS: "recoringInProgress",
  RECORD_IN_STOPPED: "recoringStopped",
};
export const SPECIAL_CHAR_PATTERN = /[!@#$%^&*()_+[\]{};':"\\|,.<>/?~]/;
export const ERROR_MESSAGE = {
  ROOM_NAME: {
    REQUIRED: "Please enter room name.",
    MIN_MAX: "Room name must be between 3 and 25 characters.",
    SPECIAL_CHAR:
      "Only letters, numbers, underscores, and hyphens are allowed.",
  },
  EMAIL: {
    REQUIRED: "Email is required.",
    INVALID: "Please enter valid email address.",
  },
  PASSWORD: {
    REQUIRED: "Password is required.",
    INVALID:
      "Password must contain at least one number, one uppercase letter, one lowercase letter, one special character, and be at least 8 characters long.",
  },
  DISPLAY_NAME: {
    REQUIRED: "Please enter your name.",
    SPECIAL_CHAR: "Special characters are not allowed.",
  },
  ROOM_CREATION_FAILED: "Failed to create a room.",
  UNEXPECTED_ERROR: "An unexpected error occurred."
};

// Define method type constants as strings
export const METHOD_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};
export const API_ENDPOINT = {
  RESET_PASSWORD: `users/reset-password`,
  SOCAIL_AUTH: `users/social-auth`,
  CHANGE_PASSWORD: `users/change-password`,
  RESEND_VERIFICATION: `users/resend-verification-email`,
  FORGOT_PASSWORD: `users/forgot-password`,
  LOGIN: `users/login`,
  REGISTER: `users/registration`,
  GUEST_REGISTER: `users/registerAsGuest`,
  CHECK_EMAIL_AVAILIBILITY: `users/check-email`,
  UPDATE_PROFILE: `users/update-profile`,
  RECENT_MEETING: `users/recent-meetings`,
  SAVE_BIRTH_YEAR: `users/save-birth-year`
};
