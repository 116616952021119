export const setSelectedAudioDevice = (deviceId) =>
	({
		type    : 'CHANGE_AUDIO_DEVICE',
		payload : { deviceId }
	});

export const setSelectedWebcamDevice = (deviceId) =>
	({
		type    : 'CHANGE_WEBCAM',
		payload : { deviceId }
	});

export const setFacingMode = (mode) => 
	({
		type: 'SET_FACING_MODE',
		payload: mode
	});
export const setVideoResolution = (resolution) =>
	({
		type    : 'SET_VIDEO_RESOLUTION',
		payload : { resolution }
	});

export const setDisplayName = (displayName) =>
	({
		type    : 'SET_DISPLAY_NAME',
		payload : { displayName }
	});

export const setEmail = (email) =>
	({
		type    : 'SET_EMAIL',
		payload : { email }
	});

export const toggleAdvancedMode = () =>
	({
		type : 'TOGGLE_ADVANCED_MODE'
	});

export const togglePermanentTopBar = () =>
	({
		type : 'TOGGLE_PERMANENT_TOPBAR'
	});

export const setLastN = (lastN) =>
	({
		type    : 'SET_LAST_N',
		payload : { lastN }
	});

export const setPeerId = (peerId) =>
	({
		type    : 'SET_PEER_ID',
		payload : { peerId }
	});

export const setUserId = (userId) =>
	({
	  type: 'SET_USER_ID',
	  payload: { userId },
	});
  
export const setUserData = (userData) =>
	({
	  type: 'SET_USER_DATA',
	  payload: { userData },
	});

export const invalidAttempts = (count) =>
	({
		type: 'INVALID_ATTEMPTS',
		payload: count,
	});

export const fetchIPInfo = (ipInfo) => 
	({
		type: 'FETCH_IP_INFO',
		payload: ipInfo,
	});
