import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Modal,
  Tooltip,
} from "@material-ui/core";
import useStyles from "./HowToUseStyles";
import CloseIcon from "@material-ui/icons/Close";
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import { fetchFile } from "../../utils/awsUtil";
import { AWS_VIDEO_UPLOAD_DIRECTORY_PATH } from "../../utils/constants";

const HowToUse = ({
  howToUseAction,
  onTopbar = false,
  showLabel,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [hide] = useState(true);
  const [signedUrl, setSignedUrl] = useState();

  const handleClose = () => {
    setOpen(false);
  }

  const howToUseHandler = () => {
    setOpen(true);
    howToUseAction();
  };
  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      howToUseHandler();
    }
  };

  const handleVideoPlay = async () => {
    const uri = AWS_VIDEO_UPLOAD_DIRECTORY_PATH;
    const signedUrlInfo = await fetchFile(uri);
    
    setSignedUrl(signedUrlInfo);
  };

  useEffect(() => {
    handleVideoPlay();
  }, []);

  return (
    <>
      <Box
        onClick={howToUseHandler}
        onKeyDown={handleOnKeyDown}
        className={classes.howToUseBtn}
      >
        {onTopbar ? (
          <Tooltip title="How to use">
            <IconButton
              className={`${classes.actionButton} grayCircleBtn blueCircleBtn`}
            >
              <OndemandVideoOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          showLabel && (
            <Box
              mr="24px"
              tabIndex="0"
              className={classes.btnStyle}
              display="flex"
              alignItems="center"
            >
                           <span className={classes.footerLink}>How to use</span>
            </Box>
          )
        )}

      </Box>
      {hide && (
        <Modal open={open}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Box
              component="h2"
              color="common.white"
              textAlign="center"
              className={`${classes.topHeadingBg} ${classes.howToUseHeading}`}
            >
              How to use
              <Tooltip title="close">
                <IconButton
                  className={`${classes.howTouseTopCloseIcon} ${classes.grayCircleBtn} ${classes.closeIcon}`}
                  onClick={handleClose}
                >
                  <CloseIcon style={{ fontSize: "20px" }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className={classes.videoView}>
              <video
                width="100%"
                height="100%"
                controls
                disablePictureInPicture
                autoPlay
              >
                <source src={signedUrl} type="video/mp4" />
              </video>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
HowToUse.defaultProps = {
  howToUseAction: () => {},
};
export default HowToUse;
