import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,

  CircularProgress,
} from "@material-ui/core";
import useStyles from "./DashboardStyles";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { withRoomContext } from "../../../RoomContext";
import {
  formatDate,
  generateExtraData,
  generateRandomString,
  makeRequest,
} from "../../../utils/utils";
import { API_ENDPOINT, METHOD_TYPES } from "../../../utils/constants";
import * as settingsActions from "../../../actions/settingsActions";
import * as roomActions from "../../../actions/roomActions";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const Dashboard = ({ roomClient, changeDisplayName, setJoiningRoom }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const ipInfo = useSelector((state) => state.settings.ipInfo);
  const userInfo = useSelector((state) => state.settings?.userData);
  // const displayName = `${userInfo?.firstName} ${userInfo?.lastName || ""}`;
  const [userMeetings, setUserMeetings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchUsersMeetings = async () => {
      try {
        const response = await makeRequest(
          METHOD_TYPES.GET,
          API_ENDPOINT.RECENT_MEETING,
          null,
          userInfo.token
        );
        if (isMounted) {
          setUserMeetings(response.data);
          setLoading(false);
          console.log("Data", response)
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchUsersMeetings(); // Initial call to fetch users

    const intervalId = setInterval(fetchUsersMeetings, 5000); // Schedule next call every 5 seconds

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false on unmount
      clearInterval(intervalId); // Clear the interval on unmount
    };
  }, [userInfo.token]);

  const joinRoom = async (data) => {
    try {
      setJoiningRoom(true);
      const systemInfo = generateExtraData();
      const extraData = {
        ipInfo,
        systemInfo,
        isGuest: false,
      };
      dispatch(
        settingsActions.setUserData({
          ...userInfo,
          roomId: data.roomName,
          joinVideo: false,
        })
      );
      const displayName = `${userInfo?.firstName} ${userInfo?.lastName || ""}`;
      changeDisplayName(displayName);
      dispatch(settingsActions.setEmail(userInfo.email));
      await roomClient.join({
        roomId: data.roomName,
        joinVideo: false,
        peerId: generateRandomString(),
        extraData,
      });
      history.push(`/${data.roomName}`);
    } catch (err) {
      setJoiningRoom(false);
    }
  };

  return (
    <>

              <Box bgcolor="common.white" borderRadius={16} p={2}>
                <Typography
                  variant="h5"
                  component="h5"
                >
                  Recent Activity
                </Typography>

                {loading ? (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                ) : userMeetings.length > 0 ? (
                  <Box mt={2} pr={1} className={classes.activityBox}>
                    {userMeetings.map((meeting) => (
                      <Box border={1} borderColor='white.light' borderRadius={16} key={meeting._id} p={1.5} mb={1}>
                        <Box display='flex' justifyContent='space-between' pb={1} alignItems='center'>
                          <Typography component="h5" variant="h5" className={classes.roomName}>{meeting.roomName}</Typography>
                          <Box display='flex' alignItems='center' pr={1} color='success.main' fontWeight={500} fontSize={14} minWidth={120} justifyContent="flex-end">
                             
                             {!meeting.active ? (
                                 <> 
                                  <Box mr={0.5} display='flex'><CheckCircleIcon color="inherit" fontSize="small"/></Box> Completed </>
                                ) : (
                                  <Button
                                    size="small"
                                    onClick={() => joinRoom(meeting)}
                                    variant="contained"
                                    color="primary"
                                    style={{fontSize:"12px"}}
                                  >
                                    Join
                                  </Button>
                                )}
                          </Box>
                        </Box>
                        <Box display='flex' justifyContent='space-between'>
                          <Box pr={1}>
                            <Typography component="p" variant="body1">Start Time</Typography>
                            <Typography component="h6" variant="body1" style={{fontWeight:"500"}}>{formatDate(meeting.startTime)}</Typography>
                          </Box>
                          <Box pl={1}>
                            <Typography component="p" variant="body1">End Time</Typography>
                            <Typography component="h6" variant="body1" style={{fontWeight:"500"}}>{formatDate(meeting.endTime)}</Typography>
                          </Box>
                        </Box>
                        {/* <Box display='flex' justifyContent='space-between'>
                          <Box pr={1}>
                            <Typography component="p" variant="body1">Call Duration</Typography>
                            <Typography component="h6" variant="body1" style={{fontWeight:"500"}}><Box display='flex' alignItems='center' gridColumnGap={4}><VideocamOutlinedIcon/> 00:20:10</Box></Typography>
                          </Box>
                        </Box> */}
                      </Box>
                     ))}
                    
                  </Box>
                ) : (
                  <Typography variant="body1" mt={2}>
                    No recent activity to show.
                  </Typography>
                )}
              </Box>

    </>
  );
};

Dashboard.propTypes = {
  roomClient: PropTypes.any.isRequired,
  room: PropTypes.object.isRequired,
  displayName: PropTypes.string.isRequired,
  changeDisplayName: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  room: state.room,
  displayName: state.settings.displayName,
  displayNameInProgress: state.me.displayNameInProgress,
  loginEnabled: state.me.loginEnabled,
  loggedIn: state.me.loggedIn,
  myPicture: state.me.picture,
  joiningRoom: state.room.joiningRoom,
});

const mapDispatchToProps = (dispatch) => ({
  changeDisplayName: (displayName) => {
    dispatch(settingsActions.setDisplayName(displayName.trim()));
  },
  setJoiningRoom: (value) => {
    dispatch(roomActions.setJoiningRoom(value));
  },
});

export default withRoomContext(
  connect(mapStateToProps, mapDispatchToProps, null, {
    areStatesEqual: (next, prev) => {
      return (
        prev.room.inLobby === next.room.inLobby &&
        prev.room.signInRequired === next.room.signInRequired &&
        prev.settings.displayName === next.settings.displayName &&
        prev.me.displayNameInProgress === next.me.displayNameInProgress &&
        prev.me.loginEnabled === next.me.loginEnabled &&
        prev.me.loggedIn === next.me.loggedIn &&
        prev.me.picture === next.me.picture &&
        prev.room.Dashboard === next.room.Dashboard
      );
    },
  })(Dashboard)
);
