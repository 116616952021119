const colorOverride = {
  common: {
    white: "#ffffff",
    black: "#000000",
    borderColor: "#ddd",
    primaryBorder: "#03C2FE",
    circleBtnColor: "rgba(0, 0, 0, 0.54)",
    bgColor: "#f8fbff",
    profileBgColor: "#dbdfe2",
    shadowColor: "0px 0px 4px 0px rgb(171 171 171 / 50%)",
    bottomBorderColor: "#03c2fe8a",
    urlIconColor: "#ddd6",
    badgeBorderColor: "#a2a2a26b",
    lightWhite: "#DBDBDB",
    lightGrayBorder: "#D9D9D9",
    // New variable code
    gary_200: "#7D7D7D",
    lightBlack: "#010101",
    darkGrey: "#2D2D2D",
    brightRed: "#EF3826",
    brightBlue: "#563FEB",
    lightGrey: "#FAFAFA",
    grayishBlue:"#F1F5F9",
    netural50:"#7B8287",
    neutral100:"#273141",
    netural150:"#717579",
    dodgerBlue:"#026EDD0F",
    gray90:"#E5E5E5",
    grayLight50:'#EFF4FA',
    grayDisabled: '#F6F7F8',
    grayBorder: '#D4D5D5'
  },
  palette: {
    primary: {
      main: "#3F8DFD",
      dark: "#62A9F9",
      extraDark: "#026EDD",
      light: "#DBECFF",
      extraLight:'#DFEBF8'
    },
    secondary: {
      main: "#19857b",
      light: "#333333",
      extraLight: "#8c8c8d",
      dark:'#E0F6F3'
    },
    error: {
      main: "#e84e4f",
      dark: "#E50000",
    },
    success: {
      main: "#009900",
      dark: "#00C44D",
    },
    info: {
      main: "#e0e0e0",
      dark: "rgba(0, 0, 0, 0.87)",
      light: "#b2b2b2",
      footerBgColor: "#0f0f0f",
      lightBlue: "#63A4E7",
      text: "#6A6A6A",
      footerTextColor: "#f0f0f0",
    },
    black: {
      main: "#1F1F20",
      light: "#323134",
      dark: "#1B1A1D",
      extraLight: "#27292B",
      extraDark: "#2B2D2E",
      extraDarkSm: "#404243",
      disabled: "#646464",
      extraLightSm:'#212932'
    },
    white: {
      main: "#ffffff",
      light: "#DBDBDB",
    },
  },
};

export default colorOverride;
