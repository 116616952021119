import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import propTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import ReportIssue from '../../ReportIssue/ReportIssue';
import HowToUse from "../../HowToUse/HowToUse";
import useStyles from "./FooterMenuStyles";


const FooterMenu = ({ linkNames, specificContentRef, navbarType }) => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const classes = useStyles();
  const [state, setState] = useState(
    localStorage.getItem("howItWorksClicked") || ""
  );

  let stateLocal;
  const handleHowItWorksClick = () => {
    localStorage.setItem("howItWorksClicked", "1");
    setState(1);
  };

  const handleLinkClick = () => {
    localStorage.setItem("howItWorksClicked", "2");
    setState(2);
  };
  stateLocal = localStorage.getItem("howItWorksClicked", "2");
  useEffect(() => {
    if (
      specificContentRef &&
      specificContentRef.current &&
      stateLocal === "1"
    ) {
      setTimeout(() => {
        specificContentRef.current.scrollIntoView({ behavior: "smooth" });}, 500);
       
      localStorage.setItem("howItWorksClicked", "2");
      setState("2");
    }
  }, [currentRoute, specificContentRef, state, stateLocal]);

  return (
    <Box
      className={classes.footerLinks}
      marginRight="-22px"
      display="flex"
      justifyContent="space-around"
      pt={1}
    >
      {navbarType !== "footer" ? (
      <>
      <ReportIssue showContact={true}/>
      </>
      ) : null}
      {linkNames.map((item, index) =>
        currentRoute === "/" && item.name === "How it works" ? (
          <Link
            to=""
            className={classes.headerLinks}
            key={index}
            onClick={() => {
              handleHowItWorksClick();
            }}
          >
            {item.name}
          </Link>
        ) : item.name === "About us" || item.name === "How it works" ? (
          <Link
            className={classes.headerLinks}
            to={item.linkUrl}
            key={index}
            onClick={(e) => {
              if (item.name === "How it works") {
                handleHowItWorksClick();
              } else {
                handleLinkClick();
              }
            }}
          >
            {item.name}
          </Link>
        ) : (
          <Link className={classes.footerLink} to={item.linkUrl} key={index}>
            {item.name}
          </Link>
        )
      )}
 {navbarType === "footer" ? (
  <>
  <HowToUse showLabel={true} /> 
  
  </>
) : null}
    </Box>
  );
};

FooterMenu.propTypes = {
  linkNames: propTypes.array,
};

export default FooterMenu;
