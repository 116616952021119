import { scrollToMessageId } from "../utils/utils";

const files = (state = {}, action) => {
  switch (action.type) {
    case "ADD_FILE": {
      const { peerId, magnetUri, displayName, fileName, messageId } =
        action.payload;

      const newFile = {
        active: false,
        progress: 0,
        files: null,
        peerId: peerId,
        displayName: displayName,
        fileName: fileName,
        magnetUri: magnetUri,
        time: Date.now(),
        messageId: messageId
      };

      setTimeout(() => {
				// scroll to new message
				scrollToMessageId(null, messageId); 
			}, 10);

      return { ...state, [magnetUri]: newFile };
    }

    case "ADD_FILE_HISTORY": {
      const { fileHistory } = action.payload;
      const newFileHistory = {};

      // eslint-disable-next-line
      fileHistory.map((file) => {
        const newFile = {
          active: false,
          progress: 0,
          files: null,
          ...file,
        };

        newFileHistory[file.magnetUri] = newFile;
      });

      return { ...state, ...newFileHistory };
    }

    case "SET_FILE_ACTIVE": {
      const { magnetUri } = action.payload;
      const file = state[magnetUri];

      const newFile = { ...file, active: true };

      return { ...state, [magnetUri]: newFile };
    }

    case "SET_FILE_INACTIVE": {
      const { magnetUri, time } = action.payload;
      const file = state[magnetUri];

      const newFile = {
        ...file,
        active: false,
        progress: 0,
        request: "",
        time,
      };

      return { ...state, [magnetUri]: newFile };
    }

    case "SET_FILE_PROGRESS": {
      const { magnetUri, progress, source } = action.payload;
      const file = state[magnetUri];

      const newFile = { ...file, progress: progress, source: source };

      return { ...state, [magnetUri]: newFile };
    }

    case "SET_FILE_DONE": {
      const { magnetUri, sharedFiles } = action.payload;
      const file = state[magnetUri];

      const newFile = {
        ...file,
        files: sharedFiles,
        progress: 1,
        active: false,
        timeout: false,
      };

      return { ...state, [magnetUri]: newFile };
    }

    case "FILE_REMOVE": {
      const { magnetUri } = action.payload;
      delete state[magnetUri];

      return { ...state };
    }

    case "SET_FILE_REQUEST": {
      const { magnetUri, request } = action.payload;
      const file = state[magnetUri];

      const newFile = {
        ...file,
        request: request || "",
      };

      return { ...state, [magnetUri]: newFile };
    }

    case "RE_INITIALIZED_ROOM": {
            return {};
    }

    case 'ADD_FILES_WITH_CHANGED_DISPLAY_NAME':
		{
			const { displayName, peerId } = action.payload
      const result = Object.values(state)
			const files = result.length > 0 && result.map((file)=>{
				if(file.peerId === peerId){
					file.displayName = displayName;
				}
				return file
			})
    
			return files.length > 0 ? { ...state } : state;
			
		}

    default:
      return state;
  }
};

export default files;
