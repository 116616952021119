import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    marginTop: 8,
    color:theme.palette.black.main,
    lineHeight:'28px',
    fontWeight:500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "20px",
    },
  },
}));

const SubHeading = ({ subHeadingText }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="subtitle1"
      component="p"
      className={classes.subHeading}
    >
      {subHeadingText}
    </Typography>
  );
};

export default SubHeading;
