import { combineReducers } from 'redux';
import chat from './chat';
import consumers from './consumers';
import files from './files';
import lobbyPeers from './lobbyPeers';
import me from './me';
import notifications from './notifications';
import peers from './peers';
import peerVolumes from './peerVolumes';
import producers from './producers';
import recorder from './recorder';
import room from './room';
import settings from './settings';
import toolarea from './toolarea';

export default combineReducers({
  room,
  me,
  producers,
  peers,
  lobbyPeers,
  consumers,
  peerVolumes,
  notifications,
  chat,
  toolarea,
  files,
  recorder,
  settings,
});
