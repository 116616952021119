/**
 * Create a function which will call the callback function
 * after the given amount of milliseconds has passed since
 * the last time the callback function was called.
 */
export const idle = (callback, delay) => {
  let handle;

  return () => {
    if (handle) {
      clearTimeout(handle);
    }

    handle = setTimeout(callback, delay);
  };
};

export const stringAvatar = (name1) => {
  return {
    sx: {
      bgcolor: '#000',
      color: '#03C2FE',
    },
   
    children: makeNameInitials(name1),
  };
};

export const getFirstName = (fullName) => {
  const nameParts = fullName.trim()?.split(' ');
  return nameParts[0];
}

export const makeNameInitials = (nameString) => {
  const fullName = nameString.split(' ');
  const firstLetterInfo = fullName?.shift();
  const firstLetter = firstLetterInfo?.charAt(0);
  const secondWord = firstLetterInfo?.charAt(1);
  const secondLetter = getSecondLetterOfName(fullName);
  const initials =
    (firstLetter ? firstLetter : '') +
    (secondLetter ? secondLetter : secondWord);
  return initials.toUpperCase();
};

const getSecondLetterOfName = (nameList) => {
  let wordName = '';
  for (const element of nameList) {
    if (element) {
      wordName = element.charAt(0);
      break;
    }
  }  
  return wordName;
};

export const isDevEnv = () => {
  return process.env.REACT_APP_SERVER_URL ===
    "https://msdev.galaxysofttech.co.in/backend/";
}