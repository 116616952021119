// Configuration
const SLOW_NETWORK_LIMIT = 3;


export function getSignalingUrl(peerId, roomId) {
  const url = `${
    process.env.REACT_APP_SERVER_URL || ''
  }?peerId=${peerId}&roomId=${roomId}`;

  return url;
}

export function detectSlowNetwork() {
  return navigator.connection.downlink < SLOW_NETWORK_LIMIT;
}
