import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  Box,
  Modal,
  Paper,
  InputAdornment,
  InputLabel,
  FormLabel,
  withWidth
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useRef, useState } from "react";
import useStyles from "./ReportIssueStyles";
import ReCAPTCHA from "react-google-recaptcha";
import { awsUploadFile, deleteFile, uniqueFileName } from "../../utils/awsUtil";
import {
  converFileNameForView,
  imageAcceptTypes,
  raiseAnIssue,
  validationProfileImage,
} from "../../utils/utils";
import Alert from "@material-ui/lab/Alert";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  ATTACHMENT_NAME_LENGTH,
  FILE_SIZE,
  RE_CAPTCHA_SECRET_KEY,
  ALLOWED_IMAGES,
} from "../../utils/constants";
import Delete from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import isElectron from "is-electron";
import ElectronServices from "../../utils/electronServices";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Progressbar from "../Progressbar/index";
import { sendAnalyticsEvent, GTM_EVENT, GTM_METHODS } from "../../utils/gtmUtils";

const ReportIssue = ({
  onTopbar = false,
  settings,
  actionClicked,
  showContact = false,
  width
}) => {
  let inputRef = useRef(null);
  let reCaptchaRef = useRef(null);
  const messageContainerRef = useRef(null);
  const [responseMessage, setResponseMessage] = useState({
    type: "",
    message: "",
  });
  const [fileValidationMsg, setFileValidationMsg] = useState("");
  const [requestValue, setRequestValue] = useState(
    showContact ? "Request a demo" : null
  );
  const scrollRef = useRef(null);
  const [flipFields, setFlipFields] = useState(showContact);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Name is required."),
    email: Yup.string()
      .email("Enter valid email.")
      .required("Email is required."),
    showSubject: Yup.boolean(),
    subject: Yup.string().when("showSubject", {
      is: (showSubject) => {
        return requestValue === "Report a problem"
          ? (showSubject = true)
          : (showSubject = false);
      },
      then: Yup.string().required("Issue summary is required."),
    }),
    showDiscription: Yup.boolean(),
    description: Yup.string().when("showDiscription", {
      is: (showDiscription) => {
        return requestValue === "Report a problem"
          ? (showDiscription = true)
          : (showDiscription = false);
      },
      then: Yup.string().required("Issue description is required."),
      otherwise: Yup.string().required("Message is required."),
    }),
    showPhone: Yup.boolean(),
    phone: Yup.string().when("showPhone", {
      is: (showPhone) => {
        return requestValue !== "Report a problem"
          ? (showPhone = true)
          : (showPhone = false);
      },
      then: Yup.string()
        .required("Phone number is required.")
        .matches(phoneRegExp, "Phone number is not valid."),
    }),
    isCaptchValidated: isElectron()
      ? Yup.boolean().optional()
      : Yup.boolean().oneOf([true], "Please verify the Captcha.").required(),
  });
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const resetFormFields = () => {
    reset();
    setResponseMessage({
      type: "",
      message: "",
    });
    return;
  };

  const onSubmit = async (data) => {
    sendAnalyticsEvent(GTM_EVENT.CONTACT_US, GTM_METHODS.BUTTON, GTM_METHODS.CLICK, GTM_EVENT.CONTACT_US)
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth',  block: "center",
      inline: "start" });
    }
    setUploadingInProgress(true);
    let payload = {
      fullName: data.fullName,
      email: data.email,
      subject: data.subject || "",
      description: data.description,
      attachment: data.attachment || "",
      attachmentName: data.attachmentName || "",
      requestValue,
      phone: data.phone || "",
    };

    const response = await raiseAnIssue(payload);
    messageContainerRef.current.scrollIntoView({ behavior: "smooth" })
    if (response.isSuccess) {
      setResponseMessage({
        type: "success",
        message: "Thank you for reaching out! Your message has been successfully received.",
      });
      setTimeout(() => {
        resetFormFields();
        setUploadingInProgress(false);
        handleClose();
      }, 3000);
    } else {
      setUploadingInProgress(false);
      setResponseMessage({
        type: "error",
        message: "There is something wrong !",
      });
    }
  };

  const [open, setOpen] = useState(false);
  const [isUploadingInProgress, setUploadingInProgress] = useState(false);
  const [uploadingPercentage, setUploadingPercentage] = useState(null);
  const [fileRequest, setFileRequest] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
    setRequestValue("Request a demo");
  };
  const classes = useStyles();

  function onChange(value) {
    setValue("isCaptchValidated", !!value);
    trigger("isCaptchValidated");
  }

  const removeAttachment = () => {
    const attachmentValue = getValues("attachment");
    // remove from form field
    setValue("attachment", "");
    setValue("attachmentName", "");
    inputRef.current.value = "";

    // remove from aws s3
    deleteFile(attachmentValue);

    // trigger to update the form state of the formik hook
    trigger("attachment");
    trigger("attachmentName");
  };

  const handleFileUpload = async (e, attachmentKey) => {
    setFileValidationMsg("");
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    const fileValidation = validationProfileImage(
      file,
      FILE_SIZE,
      ALLOWED_IMAGES
    );
    if (!fileValidation.isOK) {
      const errorMsg = fileValidation.sizeError
        ? `File size should not be more than ${FILE_SIZE}MB.`
        : "Invalid file type.";
      setFileValidationMsg(errorMsg);

      // reset the info
      setTimeout(() => {
        inputRef.current.value = "";
        setFileValidationMsg("");
      }, 2500);

      return;
    }

    try {
      setUploadingInProgress(true);
      const callback = (evt) => {
        const uploadingPercentage = Math.round((evt.loaded / evt.total) * 100);
        setUploadingPercentage(uploadingPercentage);
      };

      const callbackForRequest = (requestInfo) => {
        setFileRequest(requestInfo);
      };

      const key = uniqueFileName(file.name);
      const contentType = file.type;
      const response = await awsUploadFile(
        file,
        key,
        contentType,
        callback,
        callbackForRequest
      );
      if (response.error === null) {
        setValue(attachmentKey, response?.Key);
        setValue("attachmentName", file.name);
      } else {
        inputRef.current.value = "";
      }
    } catch (error) {
    } finally {
      setUploadingPercentage(null);
      setUploadingInProgress(false);
    }
  };

  const handleChooseFileClicked = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const handleOnClick = () => {
    handleOpen();
    setValue("isCaptchValidated", false);

    if (onTopbar) {
      // set the name and email from local caching
      setValue("fullName", settings?.displayName);
      setValue("email", settings?.email);
    }

    // trigger action for parent component
    actionClicked();
  };

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOnClick();
    }
  };
  const handleChange = (event) => {
    setRequestValue(event.target.value);
    setFlipFields(true);
    reset();
    setValue("isCaptchValidated", false);
    reCaptchaRef.current.reset();
    if (onTopbar) {
      // set the name and email from local caching
      setValue("fullName", settings?.displayName);
      setValue("email", settings?.email);
    }
  };

  const handlePolicyLinkClick = () => {
    if (isElectron()) {
      ElectronServices.openElectronWindow(
        `${process.env.REACT_APP_SERVER_URL}/#/privacy-policy`
      );
    } else {
      window.open("/privacy-policy");
    }
  };

  const stopProcessing = () => {
    fileRequest.abort();
  };

  return (
    <>
      <Box
        onClick={handleOnClick}
        onKeyDown={handleOnKeyDown}
        className={`${classes.reportLink} ${classes.focusable}`}
        marginBottom="5px"
      >
        {onTopbar ? (
          <Tooltip title={"Contact us"}>
            <IconButton
              style={{ marginTop: "5px" }}
              aria-label={"Contact us"}
              className={clsx(
                classes.actionButton,
                "grayCircleBtn",
                "blueCircleBtn"
              )}
            >
              {/* <FeedbackOutlined fontSize="small" /> */}
              <MailOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
             flipFields && (
            <Box
              tabIndex="0"
              className={classes.btnStyle}
              display="flex"
              alignItems="center"
            >
              <Link className={classes.headerLinks} >Contact us</Link>
            </Box>
          )
        )}
      </Box>
      <Modal open={open} className={classes.modalPaper}>
        <Box
          component={Paper}
          style={{ outline: 0, borderRadius: "20px" }}
        >
          <Box position="relative">
            <Box
              padding="20px 20px 0 20px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              <Box flexGrow={1} textAlign={"center"}>
                <Typography variant={width === "xs" ? "h5" : "h4"}>
                  Contact us
                </Typography>
              </Box>
              <Tooltip title="Close">
                <IconButton
                  className={`${classes.closeIcon}`}
                  onClick={handleClose}
                >
                  <CloseIcon style={{ fontSize: "18px" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={classes.formDesign}
            ref={scrollRef}
          >
            {/* options */}
            <Grid container spacing={3}>
              <Grid ref={messageContainerRef}>
                <Box>
                  {responseMessage?.type === "success" && (
                    <Alert
                      variant="filled"
                      iconMapping={{
                        success: <CheckCircleOutlineIcon fontSize="inherit" />,
                      }}
                    >
                      {responseMessage.message}
                    </Alert>
                  )}
                  {responseMessage?.type === "error" && (
                    <Alert variant="filled" severity="error">
                      {responseMessage.message}
                    </Alert>
                  )}
                  {fileValidationMsg && (
                  <Alert variant="filled" severity="error">
                    {fileValidationMsg}
                  </Alert>
                )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box pb={1} className={classes.formLabelStyle}>
                  Select
                </Box>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    select
                    value={requestValue}
                    className={`${classes.inputBorder}`}
                    onChange={handleChange}
                    defaultValue="Request a demo"
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      FormLabelClasses: {
                        asterisk: classes.labelAsterisk,
                      },
                    }}
                    variant="outlined"
                  >
                    <option value="Request a demo">Request a demo</option>
                    <option value="Report a problem">Report a problem</option>
                    <option value="Other">Other</option>
                  </TextField>
                </FormControl>
              </Grid>

              {/* full name */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box pb={1} className={classes.formLabelStyle}>
                  Name <span className={classes.mandatory}>*</span>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    name="Name"
                    placeholder="Enter Your Name"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      FormLabelClasses: {
                        asterisk: classes.labelAsterisk,
                      },
                    }}
                    onKeyDown={(event) => {
                      event.stopPropagation();
                    }}
                    className={`${classes.inputField} ${classes.inputBorder}`}
                    inputProps={{
                      maxLength: 30,
                    }}
                    {...register("fullName", {
                      setValueAs: (v) => v.trim(),
                    })}
                    error={errors.fullName ? true : false}
                    style={errors.fullName ? { color: "#e84c3f" } : {}}
                  />
                  {errors.fullName ? (
                     <p className={classes.error}> {errors?.fullName?.message}</p>
                  ) : null}
                </FormControl>
              </Grid>
              {/* email */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box pb={1} className={classes.formLabelStyle}>
                  Email <span className={classes.mandatory}>*</span>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    name="email"
                    placeholder="Enter Your Email"
                    type="email"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      FormLabelClasses: {
                        asterisk: classes.labelAsterisk,
                      },
                    }}
                    onKeyDown={(event) => {
                      event.stopPropagation();
                    }}
                    className={`${classes.inputField} ${classes.inputBorder}`}
                    inputProps={{ maxLength: 100 }}
                    {...register("email", {
                      setValueAs: (v) => v,
                    })}
                    error={errors.email ? true : false}
                    style={errors.email ? { color: "#e84c3f" } : {}}
                  />
                  {errors.email ? (
                   <p className={classes.error}> {errors?.email?.message} </p>
                  ) : null}
                </FormControl>
              </Grid>
              {/* phone number & issue summary */}
              {requestValue === "Other" || requestValue === "Request a demo" ? (
                flipFields && (
                  // phone number
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box pb={1} className={classes.formLabelStyle}>
                      Phone Number <span className={classes.mandatory}>*</span>
                    </Box>
                    <FormControl fullWidth>
                      <TextField
                        name="phone"
                        placeholder="Enter Contact Number"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            asterisk: classes.labelAsterisk,
                          },
                        }}
                        onKeyDown={(event) => {
                          event.stopPropagation();
                        }}
                        className={`${classes.inputField} ${classes.inputBorder}`}
                        inputProps={{
                          maxLength: 30,
                        }}
                        {...register("phone", {
                          setValueAs: (v) => v.trim(),
                        })}
                        error={errors.phone ? true : false}
                        style={errors.phone ? { color: "#e84c3f" } : {}}
                      />
                      {errors.phone ? (
                         <p className={classes.error}> {errors?.phone?.message}</p>
                      ) : null}
                    </FormControl>
                  </Grid>
                )
              ) : (
                // Issue summary
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box pb={1} className={classes.formLabelStyle}>
                    Issue Summary <span className={classes.mandatory}>*</span>
                  </Box>
                  <FormControl fullWidth>
                    <TextField
                      name="subject"
                      placeholder="Enter Issue Summary"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                        FormLabelClasses: {
                          asterisk: classes.labelAsterisk,
                        },
                      }}
                      inputProps={{
                        maxLength: 50,
                      }}
                      variant="outlined"
                      className={`${classes.inputField} ${classes.inputBorder}`}
                      {...register("subject", {
                        setValueAs: (v) => v.trim(),
                      })}
                      onKeyDown={(event) => {
                        event.stopPropagation();
                      }}
                      error={errors.subject ? true : false}
                    />
                    {errors.subject ? (
                         <p className={classes.error}> {errors?.subject?.message}</p>
                    ) : null}
                  </FormControl>
                </Grid>
              )}
              {/* message & issue description */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box pb={1} className={classes.formLabelStyle}>
                  {requestValue === "Request a demo" || requestValue === "Other"
                    ? <FormLabel className={classes.formLabelStyle}>Message <span className={classes.mandatory}>*</span></FormLabel>
                    : <FormLabel className={classes.formLabelStyle}>Issue description <span className={classes.mandatory}>*</span></FormLabel>}
                </Box>
                <FormControl fullWidth>
                  <TextField
                    name="description"
                    type="text"
                    placeholder={
                      requestValue === "Request a demo" ||
                      requestValue === "Other"
                        ? "Enter Message"
                        : "Enter Issue Description *"
                    }
                    InputLabelProps={{
                      shrink: true,
                      FormLabelClasses: {
                        asterisk: classes.labelAsterisk,
                      },
                    }}
                    multiline
                    minRows={3}
                    maxRows={3}
                    inputProps={{
                      maxLength: 250,
                    }}
                    variant="outlined"
                    className={`${classes.inputField} ${classes.inputBorder}`}
                    {...register("description", {
                      setValueAs: (v) => v.trim(),
                    })}
                    onKeyDown={(event) => {
                      event.stopPropagation();
                    }}
                    error={errors.description ? true : false}
                  />
                  {errors.description ? (
                         <p className={classes.error}> {errors?.description?.message}</p>
                  ) : null}
                </FormControl>
              </Grid>
              {(!flipFields || requestValue === "Report a problem") && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        name="fileattach"
                        label="Attachment"
                        type="file"
                        variant="outlined"
                        style={{ cursor: "pointer", display: "none" }}
                        className={classes.inputTextField}
                        onChange={(e) => handleFileUpload(e, "attachment")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: getValues("attachment") ? (
                            <InputAdornment position="end">
                              <Tooltip title={"Remove attachment"}>
                                <Delete
                                  onClick={removeAttachment}
                                  style={{ cursor: "pointer" }}
                                />
                              </Tooltip>
                            </InputAdornment>
                          ) : null,
                          inputProps:{
                            accept: imageAcceptTypes(),
                            ref: inputRef,
                          }
                        }}
                      />
                     
                    <div className={classes.attachmentView}>
                    <Button
                        className={classes.modalbtn}
                        variant="contained"
                        onClick={handleChooseFileClicked}
                      >
                        Choose file
                      </Button>

                      {getValues("attachment") ? (
                        <div className={classes.attachmentViewAction}>
                          <InputLabel
                            htmlFor="my-input"
                            className={classes.attachmentLabel}
                          >
                            {converFileNameForView(
                              getValues("attachmentName"),
                              ATTACHMENT_NAME_LENGTH,
                              "..."
                            )}
                          </InputLabel>
                          <Tooltip title={"Remove attachment"}>
                            <Delete
                              onClick={removeAttachment}
                              className={classes.attachmentDeleteIcon}
                            />
                          </Tooltip>
                        </div>
                      ) : uploadingPercentage ? (
                        <div className={classes.attachmentUpload}>
                          {" "}
                          <Progressbar progress={uploadingPercentage} />
                          <Tooltip title="Cancel">
                            <IconButton onClick={stopProcessing} className={classes.cancelBtn}>
                              <CloseIcon style={{ fontSize: "16px" }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <InputLabel
                          htmlFor="my-input"
                          className={classes.attachmentLabel}
                        >
                          No file chosen{" "}
                        </InputLabel>
                      )}
                    </div>
                  </Grid>
                </>
              )}
              {!isElectron() && flipFields ? (
                <>
                  <Grid item xs={12} lg={12}>
                    <Box mb={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column" className={classes.responsiveCaptcha}>
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        sitekey={RE_CAPTCHA_SECRET_KEY}
                        onChange={onChange}
                      />

                      {errors.isCaptchValidated ? (
                         <p className={classes.error}>  {errors?.isCaptchValidated?.message}</p>
                      ) : null}
                    </Box>
                  </Grid>
                  {(!flipFields || requestValue === "Report a problem") && (
                    <Grid item xs={12} lg={12}>
                      <Typography variant="body2" textAlign="justify">
                        By submitting this issue you authorize Talkroom to
                        access all data in the reported issue, subject to
                        Talkroom
                        <Link
                          to={{ pathname: "" }}
                          onClick={handlePolicyLinkClick}
                        >
                          {" "}
                          privacy policy
                        </Link>
                        . This data includes screenshots and user information.
                      </Typography>
                    </Grid>
                  )}
                </>
              ) : null}
              <Box
                component={Grid}
                textAlign="end"
                item
                xs={12}
                lg={12}
                display="flex"
                justifyContent="flex-end"
              >
                
                <Button
                  className={classes.modalbtn}
                  variant="contained"
                  disabled={isUploadingInProgress}
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

ReportIssue.defaultProps = {
  onCluseModel: () => {},
  actionClicked: () => {},
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};


export default withWidth()(connect(mapStateToProps, null)(ReportIssue));

