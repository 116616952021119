import React, { useState, useEffect } from "react";
import { Box, Tooltip } from "@material-ui/core";
import Google from "../../../../images/googleLogo.webp";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const GoogleAuth = ({width ,onSuccess, btnText }) => {
    const [user, setUser] = useState(null);
    const login = useGoogleLogin({
        onSuccess: (user) => {
            setUser(user);
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                if (user && user.access_token) {
                    const response = await axios.get(
                        `https://www.googleapis.com/oauth2/v1/userinfo`,
                        {
                            headers: {
                                Authorization: `Bearer ${user.access_token}`,
                                Accept: 'application/json'
                            }
                        }
                    );
                    if (response && response.data) {
                        onSuccess(response.data);
                    }
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, [user, onSuccess]);

    return (
        <Box onClick={login} display="flex" alignItems="center" alignSelf="stretch" width="100%">
        <Tooltip title={btnText} placement={"top"}>
            <Box display="flex" fontSize={14} fontWeight={500} alignItems="center" borderRadius="44px" mt={width === "xs" ? 3 : "8px 40px"} border={"1px solid rgba(0, 0, 0, 0.12)"} style={{cursor:"pointer"}} width="100%" height="52px" alignContent="center" flexWrap="wrap" justifyContent="center">
                <Box width="24px" height="24px" mr={1}>
                    <img src={Google} alt="Google" style={{ width: "100%"}} />
                </Box>
                {btnText}
            </Box>
        </Tooltip>
        </Box>
    );
};

export default GoogleAuth;