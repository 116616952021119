import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  activityBox:{
    overflowY:'auto',
    maxHeight:500,
    [theme.breakpoints.down("sm")]:{
      maxHeight:450
    }
  },
  roomName:{
    fontWeight:500,
    textOverflow:'ellipsis',
    maxWidth:150,
    overflow:'hidden',
    whiteSpace:'nowrap'
  }
}));

export default useStyles;
