import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
        fontFamily: `'DM Sans', sans-serif`,
        fontSize: theme.typography.h6.fontSize,
        wordBreak: "break-word",
        "& + div": {
          height: "100%",
        },
        "& *": {
          fontFamily: `'DM Sans', sans-serif`,
        },
      },
      p:{
        color:theme.common.gary_200,
      },
      a: {
        textDecoration: "none",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ".MuiButton-containedPrimary": {
        color: theme.common.white,
        fontWeight: "400",
        boxShadow: "none",
      },
      ".MuiButton-outlinedPrimary": {
        fontWeight: "400",
        boxShadow: "none",
      },

      ".MuiBackdrop-root": {
        backgroundColor: "transparent",
        display:'none'
      },

      ".blueCircleBtn": {
        color: theme.common.white,
        border: "none !important",
        padding: `${theme.spacing(1)}px !important`,
        boxShadow: "rgb(151 151 151 / 70%) 0px 0px 3px 0px",
        borderRadius: "50% !important",
        backgroundColor: theme.palette.primary.main,
        minWidth: "37px",
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          boxShadow: "none",
          opacity: ".8",
        },
      },
      ".grayCircleBtn": {
        color: theme.common.circleBtnColor,
        border: "none !important",
        padding: `${theme.spacing(1)}px !important`,
        boxShadow: "rgb(151 151 151 / 70%) 0px 0px 3px 0px",
        borderRadius: "50% !important",
        backgroundColor: theme.common.white,
        minWidth: "37px",
        "&:hover": {
          backgroundColor: theme.common.borderColor,
          opacity: ".8",
          color: theme.common.circleBtnColor,
        },
        "& .MuiBadge-anchorOriginTopRightRectangle": {
          top: "-5px",
          right: "-5px",
          color: theme.common.white,
        },
      },
      "input:focus": {
        outline: "none",
      },
      ".redCircleBtn": {
        backgroundColor: theme.palette.error.main,
        "&:hover": {
          backgroundColor: theme.palette.error.main,
        },
      },
      ".idNameBox":{
        padding:"0px 0px 0px 20px",
        borderRadius: "50px",
        border: `1px solid ${theme.common.borderColor}`,
        color: theme.palette.secondary.extraLight,
        fontWeight: 600,
        boxShadow:
          "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
          '& svg':{
            fontSize:"24px",
            transform:"rotate(127deg)",
          },
          '& p':{
            fontWeight: 600,
          }
      },
          '.topHeadingBg':{
        position:"relative",
        fontSize:"22px",
        fontWeight: 600,
        backgroundColor:theme.common.white, 
        color:theme.palette.primary.main,
        width:"100%",  
        borderRadius: "10px 10px 0 0",
        borderBottom:`1px solid ${theme.common.borderColor}`,
      },
      '.topCloseIcon':{
        position:"absolute",
        cursor:"pointer",
        padding:"6px !important", 
        minWidth:"32px"
      },
      '.textFontSize':{
        fontSize:"15px"
      },
      ".MuiSnackbarContent-root":{
        minWidth:"auto",
        padding:"6px 13px",
      },
      '.MuiSnackbar-root':{
        width:"auto",
        top:-8,
        [theme.breakpoints.down('xs')]:{
          top:36
        },
        '& .MuiSnackbarContent-root':{
          background: 'transparent',
          fontSize:14,
          boxShadow:'none',
          '& .MuiSnackbarContent-message':{
            background:theme.palette.black.extraLight,
            padding:theme.spacing(1, 1.5),
            borderRadius:8,
            margin:'0 auto'
          }
        }
      },
      '.slick-dots':{
        [theme.breakpoints.down("xs")]: {
          display:"none !important",
        },
      },
      '.slick-list':{
        [theme.breakpoints.down("sm")]: {
          overflowY:"auto",
        },
      },
     
      ".MuiSelect-select:focus":{
        backgroundColor: "transparent",
      },
      '.MuiDialog-paper':{
        background:theme.palette.black.dark,
        [theme.breakpoints.down("xs")]:{
          margin:12
        }
      },
      '.MuiDialogTitle-root':{
        color:theme.palette.white.main,
        borderBottom:`1px solid ${theme.palette.black.light}`
      },
      '.primaryMainBtn':{
        fontSize: 16,
        minHeight: 48,
        borderRadius: 8,
        border:`1px solid transparent`,
        '&:hover':{
          borderColor: `${theme.palette.primary.main}`,
          background:'transparent',
          color:theme.palette.primary.main
        }
      },
      '.primaryMainOutlineBtn':{
        fontSize: 16,
        minHeight: 48,
        borderRadius: 8,
        border:`1px solid ${theme.palette.black.light}`,
        color:theme.palette.white.main,
        background:'transparent',
        '&:hover':{
          border:`1px solid ${theme.palette.primary.main}`,
          color:theme.palette.primary.main,
          background:'transparent',
        }
      },
      '.react-transform-wrapper, .react-transform-component':{
        height:'100% !important',
        width:'100% !important'
      }
    },
  }),
);

const GlobalStyles = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
