export const addFile = (peerId, magnetUri, displayName, fileName, messageId) =>
	({
		type    : 'ADD_FILE',
		payload : { peerId, magnetUri, displayName, fileName, messageId }
	});

export const addFileHistory = (fileHistory) =>
	({
		type    : 'ADD_FILE_HISTORY',
		payload : { fileHistory }
	});

export const setFileActive = (magnetUri) =>
	({
		type    : 'SET_FILE_ACTIVE',
		payload : { magnetUri }
	});

export const setFileInActive = (magnetUri, time) =>
	({
		type    : 'SET_FILE_INACTIVE',
		payload : { magnetUri, time }
	});

export const setFileRequest = (magnetUri, request = '') =>
	({
		type    : 'SET_FILE_REQUEST',
		payload : { magnetUri, request }
	});


export const setFileProgress = (magnetUri, progress, source = '') =>
	({
		type    : 'SET_FILE_PROGRESS',
		payload : { magnetUri, progress, source }
	});

export const setFileDone = (magnetUri, sharedFiles) =>
	({
		type    : 'SET_FILE_DONE',
		payload : { magnetUri, sharedFiles }
	});

export const removeFile = (magnetUri) =>
	({
		type    : 'FILE_REMOVE',
		payload : { magnetUri }
	});

export const addFilesWithChangedDisplayName = (displayName, peerId) =>
	({
		type 	: 'ADD_FILES_WITH_CHANGED_DISPLAY_NAME',
		payload	: { displayName, peerId }
	})
