import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  Heading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
      lineHeight: "26px",
    },
  },
}));

const Heading = ({ headingText }) => {
  const classes = useStyles();
  return (
    <Typography variant="h3" component="h3" className={classes.Heading}>
      {headingText}
    </Typography>
  );
};

export default Heading;
