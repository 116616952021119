import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { stringAvatar } from '../../utils';
const AvatarView = ({
  isName,
  profileUrl,
  altName,
  styles,
  className,
  nameInitial,
}) => {
  return profileUrl ? (
    <Avatar
      alt={altName}
      src={profileUrl}
      style={styles}
      className={className}
    />
  ) : isName ? (
    <Avatar style={styles} className={className} {...stringAvatar(nameInitial)}>
     
    </Avatar>
  ) : (
    <Avatar src="/avatar.jpg" style={styles} className={className} />
  );
};

AvatarView.defaultProps = {};
AvatarView.propTypes = {
  isName: PropTypes.bool,
  profileUrl: PropTypes.string,
  styles: PropTypes.string,
  className: PropTypes.string,
  altName: PropTypes.string,
  nameInitial: PropTypes.string,
};
export default AvatarView;
