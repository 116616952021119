import colorOverride from "../color"
const ButtonOverRide = {
    MuiButton:{
        root:{
            borderRadius:"50px",
            textTransform: 'capitalize',
            fontFamily:`'DM Sans', sans-serif`,
        },
        label:{
            fontWeight:500,
        },
        containedSizeLarge:{
            fontSize:'18px',
            minHeight:'56px'
        },
    },
    MuiIconButton:{
        root:{
            minWidth:48,
            minHeight:48,
            background:colorOverride.palette.black.extraLight,
            border:`1px solid ${colorOverride.palette.black.extraLight}`,
           '&:hover':{
            backgroundColor:colorOverride.palette.primary.dark
           }
        },
        label:{
            color:colorOverride.common.white
        },
        
    }
}

export default ButtonOverRide