import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily:theme.typography.fontFamily.medium,
    fontWeight:500,
    fontSize: "14px",
  },
  inputBorder: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 52,
      border: `1px solid ${theme.common.lightGrayBorder}`,
      borderRadius: "44px",
      background:theme.common.lightGrey,
      [theme.breakpoints.up("sm")]:{
        marginBottom:0
      },
      '& .MuiOutlinedInput-input':{
        fontSize:18,
        [theme.breakpoints.down("450")]: {
          fontSize:14,
        },
      }
    },
    [theme.breakpoints.down("450")]: {
      margin: "auto",
    },
  },
  '& .MuiDivider-root': {
    background: 'rgba(0, 0, 0, 0.10)'
  },
  registerBtn: {
    height: "48px",
    marginTop: "32px",
    padding: "10px 16px",
    "& .MuiButton-label": {
      fontFamily:theme.typography.fontFamily.medium,
      fontWeight:500,
    },
  },
  inputNumber: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    }
  },
  error: {
    color: "red",
    fontSize: "14px",
    margin: "4px 14px 0",
    fontFamily: `'DM Sans', sans-serif`,
    [theme.breakpoints.down("450")]: {
      fontSize: "12px",
    },
  },
  snackbarMessage: {
    fontSize: "13px",
    alignItems: "center",
      '& button': {
          minWidth: "22px !important",
          minHeight: "22px !important",
          border: "1px solid transparent",
          background: "none",
          '& svg': {
            color: theme.common.black
          },
          '&:hover':{
            background: "none"
          },
    },
  },
  checkBox: {
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap:"nowrap",
      alignItems:"center"
    },
    [theme.breakpoints.up("1730")]: {
      "& .MuiFormGroup-root": {
        alignItems:"center"
      },
    },
    [theme.breakpoints.down("1280")]: {
      "& .MuiFormGroup-root": {
        alignItems:"center"
      },
    },
    [theme.breakpoints.down("480")]: {
      "& .MuiFormGroup-root": {
        alignItems:"flex-start"
      },
    },
    '& p:last-child':{
      marginInline: '35px !important'
    }
  },
  checkBoxLabel: {
    maxHeight: "26px",
    maxWidth: "30px",
    color: '#000',
    "& .MuiIconButton-root": {
      background: "transparent",
      border: "none",
      minWidth: "36px"
    },
    "& .MuiIconButton-label": {
      color: `${theme.common.grayBorder} !important`  
    },
    "& .Mui-checked": {
      "& .MuiIconButton-label": {
        color: `${theme.palette.primary.extraDark} !important`  
      }
    },
  },
  adornmentIcon: {
    '& .MuiIconButton-root': {
      backgroundColor: 'unset !important',
      border: 'unset !important',
      '& span':{
        color: theme.palette.black.extraDark
      }
    }
  },
  link: {
    color: theme.palette.primary.extraDark,
    textDecoration:"underline",
    fontFamily:theme.typography.fontFamily.medium,
    fontWeight:500,
    [theme.breakpoints.down("450")]: {
      fontSize: "14px",
    },
  },
  iconBtn: {
    alignItems: "center",
    "& .MuiAlert-message": {
      fontSize: "16px",
      width:"100%",
      [theme.breakpoints.down("450")]: {
        fontSize:14,
      },
    },
    "& .MuiIconButton-root": {
      minHeight: "24px",
      minWidth: "24px",
    },
    "& button": {
      minWidth: "22px !important",
      minHeight: "22px !important",
      border: "1px solid transparent",
      background: "none",
      "& svg": {
        color: theme.common.black,
      },
      "&:hover": {
        background: "none",
      },
    },
  },
  responsiveCaptcha: {
    "@media (max-width: 430px)": {
      transform: "scale(0.75)",
    },
  }, 
}));

export default useStyles;
