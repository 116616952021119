export function createNewMessage(text, sender, name, picture, messageId, peerId)
{
	return {
		type : 'message',
		text,
		time : Date.now(),
		messageId,
		name,
		sender,
		picture,
		peerId
	};
}