import * as yup from 'yup';

export const registerSchema = yup.object().shape({
  firstName: yup.string()
  .trim()
  .required('First name is required.')
  // .matches(/^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/, 'Special characters are not allowed.')
  .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Special characters and numbers are not allowed.')
  .max(64, 'First name cannot be longer than 64 characters.'),

  email: yup.string().trim().required('Email is required.').email('Please enter a valid email address.'),
  
  birthYear: yup.number()
    .typeError('Birth year is required.')
    .test('valid-year', 'Please enter a valid birth year.', (value) => {
      if (!value) return false;
      const currentYear = new Date().getFullYear();
      const minBirthYear = currentYear - 100;
      return value >= minBirthYear && value <= currentYear;
    })
    .test('valid-age', 'You must be at least 16 years old to register.', (value) => {
        if (!value) return false;
        const currentYear = new Date().getFullYear();
        return value >= currentYear - 100 && currentYear - value >= 16;
    }),

  password: yup
    .string()
    .required('Password is required.')
    .test('trailing-space', 'Password cannot start or end with a space.', (value) => {
      if (!value) return true;
      return !(/^\s|\s$/.test(value));
    })
    .min(8, 'Password must be at least 8 characters long.')
    .max(20, 'Password must be at most 20 characters long.'),
  
  password_repeat: yup
  .string()
  .required('Confirm Password is required.')
  .test('trailing-space', 'Confirm Password cannot start or end with a space.', (value) => {
    if (!value) return true;
    return !(/^\s|\s$/.test(value));
  })
  .oneOf([yup.ref('password'), null], 'Password and Confirm Password must match.'),

  terms: yup
  .boolean()
  .oneOf([true], 'Please agree to the Terms & Conditions.')
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
  .string()
  .required('New Password is required.')
  .test('trailing-space', 'New Password cannot start or end with a space.', (value) => {
    if (!value) return true;
    return !(/^\s|\s$/.test(value));
  })
  .min(8, 'Password must be at least 8 characters long.')
  .max(20, 'Password must be at most 20 characters long.'),

password_repeat: yup
  .string()
  .required('Confirm Password is required.')
  .test('trailing-space', 'Confirm Password cannot start or end with a space.', (value) => {
    if (!value) return true;
    return !(/^\s|\s$/.test(value));
  })
  .oneOf([yup.ref('password'), null], 'New Password and Confirm Password must match.'),

})

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup
  .string()
  .required('Old Password is required.'),
  password: yup
  .string()
  .required('New Password is required.')
  .test('trailing-space', 'New Password cannot start or end with a space.', (value) => {
    if (!value) return true;
    return !(/^\s|\s$/.test(value));
  })
  .min(8, 'Password must be at least 8 characters long.')
  .max(20, 'Password must be at most 20 characters long.')
  .test('not-same-as-old', 'Old Password and New Password should not be same.', function(value) {
    const oldPassword = this.parent.oldPassword;
    return value !== oldPassword;
  }),

password_repeat: yup
  .string()
  .required('Confirm Password is required.')
  .test('trailing-space', 'Confirm Password cannot start or end with a space.', (value) => {
    if (!value) return true;
    return !(/^\s|\s$/.test(value));
  })
  .oneOf([yup.ref('password'), null], 'New Password and Confirm Password must match.'),

})

export const updateProfileSchema = yup.object().shape({
  firstName: yup.string()
  .trim()
  .required('First name is required.')
  .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Special characters and numbers are not allowed.')
  .max(64, 'First name cannot be longer than 64 characters.'),
  
  birthYear: yup.number()
    .typeError('Birth year is required.')
    .test('valid-year', 'Please enter a valid birth year.', (value) => {
      if (!value) return false;
      const currentYear = new Date().getFullYear();
      const minBirthYear = currentYear - 100;
      return value >= minBirthYear && value <= currentYear;
    })
    .test('valid-age', 'You must be at least 16 years old to register.', (value) => {
        if (!value) return false;
        const currentYear = new Date().getFullYear();
        return value >= currentYear - 100 && currentYear - value >= 16;
    }),
});