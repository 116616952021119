import { Box, Dialog } from '@material-ui/core';
import React from 'react';

const emails = ['username@gmail.com', 'user02@gmail.com'];

class Loading extends React.Component {
    state = {
        open: true,
        selectedValue: emails[1]
    };

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = (value) => {
        this.setState({ selectedValue: value, open: false });
    };

    render() {
        return (
            <div>
                <Dialog
                    open={true}
                    style={{ zIndex: '99999' }}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none'
                        }
                    }}>
                    <div style={{ overflow: 'hidden' }}>
                        <Box className="loader"
                        ></Box>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default Loading;
