import React from 'react';
import { BorderLinearProgress, useStyles } from './ProgressBarStyles';


export default function CustomizedProgressBars({ progress = 0 }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={progress} />
    </div>
  );
}
