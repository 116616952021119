import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormGroup,
} from "@material-ui/core";

import useStyles from "./ForgetPasswordStyles";

import { Link } from "react-router-dom";
import { makeRequest } from '../../../utils/utils';
import { useForm } from 'react-hook-form';
import { API_ENDPOINT, ERROR_MESSAGE, METHOD_TYPES } from "../../../utils/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Alert } from "@material-ui/lab";
import withLoader from "../../../components/hoc/withLoader";

const ForgetPassword = ({setLoadWithoutMount, handleClickOpenPopup}) => {
  const classes = useStyles();
  const scrollRef = useRef(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);

    const schema = yup.object().shape({
        email: yup
            .string()
            .required(ERROR_MESSAGE.EMAIL.REQUIRED)
            .email(ERROR_MESSAGE.EMAIL.INVALID),
    });

    const { register, handleSubmit, setValue, formState: { errors, isDirty, isValid } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onTouched',
    });

    const handleForgotPassword = async (formData) => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth',  block: "center",
        inline: "start" });
      }
      setLoadWithoutMount(true);
      try {
          const payload = {
              email: formData.email
          };
          const response = await makeRequest(
            METHOD_TYPES.POST,
            API_ENDPOINT.FORGOT_PASSWORD,
            payload
          );
          if (response.statusCode === 200) {
              setSnackbarMessage({ message: response.message, severity: 'success' });
              document.activeElement.blur();
              setValue("email", "");
          } else {
              setSnackbarMessage({ message: response.message, severity: 'error' });
              document.activeElement.blur();
          }
          setLoadWithoutMount(false);
      } catch (error) {
          console.error(error);
          setLoadWithoutMount(false);
      }
  };
  return (
    <>
      
      <Box mb={5} ref={scrollRef}>
                <Typography className={classes.subtitle}>
                  Don't worry. Resetting your password is easy, just tell us
                  your registered email address
                </Typography>
              </Box>
              {!!snackbarMessage && (
                <Box mb={2}>
                  <Alert className={classes.iconBtn} severity={snackbarMessage.severity} onClose={() => setSnackbarMessage(null)}>
                    {snackbarMessage.message}
                  </Alert>
                </Box>
              )}
              <form onSubmit={handleSubmit(handleForgotPassword)}>
                <Box mb={4}>
                  <FormGroup>
                    <TextField
                      id="email"
                      placeholder="Enter your Email *"
                      className={classes.inputBorder}
                      variant="outlined"
                      name="email"
                      type="email"
                      {...register("email")}
                      error={!!errors.email}
                    />
                  </FormGroup>
                  {errors.email && <p className={classes.error}>{errors.email.message}</p>}
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.sendBtn}
                  disabled={!isDirty || !isValid}
                >
                  Send
                </Button>
              </form>
              <Box mb={4} mt={4} textAlign="center">
                <Typography className={classes.signInBtn}>
                  Get back to&nbsp;
                  <Link className={classes.link} onClick={() => handleClickOpenPopup('login', 'Sign in')}>
                    Sign in
                  </Link>
                </Typography>
              </Box>
    </>
  );
};

export default withLoader(ForgetPassword);
