import PropTypes from 'prop-types';
import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from '../store';
import { useParams } from 'react-router';
import LoadingView from './LoadingView';
import { ReactLazyPreload } from './ReactLazyPreload';
import { Box } from '@material-ui/core';
import isElectron from 'is-electron';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ElectronServices from '../utils/electronServices';
import Home from '../modules/Home/Home';
//import JoinMeeting from '../modules/JoinMeeting/JoinMeeting';
const electron = 	isElectron() && window.require('electron');

const Room = ReactLazyPreload(() =>
  import(/* webpackChunkName: "room" */ './Room')
);

const App = (props) => {
  const { room, toolAreaOpen } = props;
  const isLoggedIn = !!store.getState()?.settings?.userData?.token || false;
  const breakPoint = useMediaQuery('(max-width:1200px)');
  let id = useParams().id;
  if (!id) {
    if(isElectron()){
      const idCheck = window.location.hash;
      const idCheckNew = idCheck.split('/');
      id = idCheckNew[1] || '';
    } else {
      const idCheck = window.location.pathname;
      const idCheckNew = idCheck.split('/');
      id = idCheckNew[1] || '';
    }
  }
  id = id.toLowerCase();
  useEffect(() => {
    Room.preload();

    return () => {
      if(isElectron()){
      // remove all electron app events
      electron.ipcRenderer.removeAllListeners();
      }
    };
  }, []);
  
  useEffect(() => {
    if (room.joined) {
      document.title = 'Talkroom Call';
      document.body.style.backgroundColor = '#1B1A1D';
    } else {
      document.title = 'Talkroom';
      document.body.style.backgroundColor = 'initial';
    }
  }, [room.joined]);
  
  if(isElectron()){
    ElectronServices.isRoomJoined(room.joined)
  }
  
  if (!room.joined) {
    //after login follow
    // if(isLoggedIn) {
    // return <JoinMeeting roomId={id}/>;
    // } else {
      
    //   return <Home />;
    // }
    return <Home roomId={id}/>;
  } else {
    
    return (
      <>
    <Box display="flex" style={{height:"100%"}}>
      <Suspense fallback={<LoadingView />}>
        <Box
        flex={breakPoint ? "0 0 100%" : (room.fullScreenConsumer === null && toolAreaOpen ? "0 0 calc(100% - 360px)" : "1")}
        width={breakPoint ? "100%" :( room.fullScreenConsumer === null && toolAreaOpen ? "calc(100% - 360px)" : "100%" )}
        position="relative"
        bgcolor="black.dark"
        >
        <Room breakPoint={breakPoint} />
        </Box>
      </Suspense>
      </Box>
      
      </>
    );
  }
};

App.propTypes = {
  room: PropTypes.object.isRequired,
  toolAreaOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  room: state.room,
  toolAreaOpen: state.toolarea.toolAreaOpen,
});

export default connect(mapStateToProps, null, null, {
  areStatesEqual: (next, prev) => {
    return (prev.room === next.room &&  prev.toolarea.toolAreaOpen !== next.toolarea.toolAreaOpen) 
  },
})(App);
