export const setRoomName = (name) => ({
  type: 'SET_ROOM_NAME',
  payload: { name },
});

export const setRoomState = (state) => ({
  type: 'SET_ROOM_STATE',
  payload: { state },
});

export const setSocketState = (state) => ({
  type: 'SET_SOCKET_STATE',
  payload: { state },
});

export const setIceCandidateState = (state) => ({
  type: 'SET_ICE_CANDIDATE_STATE',
  payload: { state },
});


export const setRoomActiveSpeaker = (peerId) => ({
  type: 'SET_ROOM_ACTIVE_SPEAKER',
  payload: { peerId },
});

export const setRoomLocked = () => ({
  type: 'SET_ROOM_LOCKED',
});

export const setRoomUnLocked = () => ({
  type: 'SET_ROOM_UNLOCKED',
});

export const setInLobby = (inLobby) => ({
  type: 'SET_IN_LOBBY',
  payload: { inLobby },
});

export const setSignInRequired = (signInRequired) => ({
  type: 'SET_SIGN_IN_REQUIRED',
  payload: { signInRequired },
});

export const setAccessCode = (accessCode) => ({
  type: 'SET_ACCESS_CODE',
  payload: { accessCode },
});

export const setJoinByAccessCode = (joinByAccessCode) => ({
  type: 'SET_JOIN_BY_ACCESS_CODE',
  payload: { joinByAccessCode },
});

export const setSettingsOpen = ({ settingsOpen }) => ({
  type: 'SET_SETTINGS_OPEN',
  payload: { settingsOpen },
});

export const setRecordingOptions = ({ recordingOptions }) => ({
  type: 'SET_RECORDING_OPTIONS',
  payload: { recordingOptions },
});

export const setRecordingOption = ({ recordingType }) => ({
  type: 'SET_DESKTOP_RECORDING',
  payload: { recordingType },
});

export const setRecordingActionText = ({ recordingActionText }) => ({
  type: 'SET_RECORDING_ACTION_TEXT',
  payload: { recordingActionText },
});

export const setLockDialogOpen = ({ lockDialogOpen }) => ({
  type: 'SET_LOCK_DIALOG_OPEN',
  payload: { lockDialogOpen },
});

export const setFileSharingSupported = (supported) => ({
  type: 'FILE_SHARING_SUPPORTED',
  payload: { supported },
});

export const toggleConsumerWindow = (consumerId) => ({
  type: 'TOGGLE_WINDOW_CONSUMER',
  payload: { consumerId },
});

export const setToolbarsVisible = (toolbarsVisible) => ({
  type: 'SET_TOOLBARS_VISIBLE',
  payload: { toolbarsVisible },
});

export const setDisplayMode = (mode) => ({
  type: 'SET_DISPLAY_MODE',
  payload: { mode },
});

export const setSelectedPeer = (selectedPeerId) => ({
  type: 'SET_SELECTED_PEER',
  payload: { selectedPeerId },
});

export const setSpotlights = (spotlights) => ({
  type: 'SET_SPOTLIGHTS',
  payload: { spotlights },
});

export const toggleJoined = () => ({
  type: 'TOGGLE_JOINED',
});

export const reInitializeRoomStore = () => ({
  type: 'RE_INITIALIZED_ROOM',
});

export const toggleConsumerFullscreen = (consumerId) => ({
  type: 'TOGGLE_FULLSCREEN_CONSUMER',
  payload: { consumerId },
});

export const setDestroyTimer = (destroyTimer) => ({
  type: 'SET_DESTROY_TIMER',
  payload: destroyTimer,
});

export const setShowWhiteboard = () => ({
  type: 'SET_SHOW_WHITEBOARD',
});

export const setWhiteboardInitiator = (initiatorStatus) => ({
  type: 'SET_WHITEBOARD_INITIATOR',
  initiatorStatus,
});

export const setHideWhiteboard = () => ({
  type: 'SET_HIDE_WHITEBOARD',
});

export const setFullScreen = (fullScreen) => ({
  type: 'SET_FULLSCREEN',
  payload: { fullScreen },
});

export const setWindowConsumer = () => ({
  type: 'SET_WINDOW_CONSUMER',
});

export const setJoiningRoom = (value) => ({
  type: 'SET_JOINING_ROOM',
  payload: value
});

export const setShowHideOpen = () => ({
  type: 'SET_SHOW_HIDE_OPEN',
});

export const setCallTimer = (callTimer) => ({
  type: 'SET_CALL_TIMER',
  payload: callTimer,
});

export const setActiveSpeaker = (activeSpeaker) => ({
  type: 'SET_ACTIVE_SPEAKER',
  payload: activeSpeaker,
});

export const setPeerName = (peerName) => ({
  type: 'SET_PEER_NAME',
  payload: peerName,
});

export const setIsRoomCreated = (state) => ({
    type: 'SET_IS_ROOM_CREATED',
    payload: state,
});
