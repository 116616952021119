import * as yup from "yup";

export const JoinFormSchema = yup.object().shape({
  roomName: yup
    .string()
    .trim()
    .required("Room name is required.")
    .matches(
        /^[A-Za-z0-9_-]*$/,
        "Special characters are not allowed."
    )
    .max(25, "Room name must be between 3 and 25 characters")
    .min(3, "Room name must be between 3 and 25 characters"),
  fullName: yup.string().trim(),
  email: yup
    .string()
    .trim()
    .required("Email is required.")
    .email("Please enter a valid email address."),
    terms: yup
    .boolean()
    .oneOf([true], 'Please agree to the Terms & Conditions.') 
});
