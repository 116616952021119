import debug from "debug";
import { setLogs } from "./utils/utils";

const APP_NAME = "multiparty-meeting";

export default class Logger {
  constructor(prefix) {
    if (prefix) {
      this._debug = debug(`${APP_NAME}:${prefix}`);
      this._warn = debug(`${APP_NAME}:WARN:${prefix}`);
      this._error = debug(`${APP_NAME}:ERROR:${prefix}`);
    } else {
      this._debug = debug(APP_NAME);
      this._warn = debug(`${APP_NAME}:WARN`);
      this._error = debug(`${APP_NAME}:ERROR`);
    }

    
    // Override console methods to send logs to the server via API
    this._debug.log = (message) => this.sendLogToServer("debug", message);
    this._warn.log = (message) => this.sendLogToServer("warn", message);
    this._error.log = (message) => this.sendLogToServer("error", message);
  }

  get debug() {
    return this._debug;
  }

  get warn() {
    return this._warn;
  }

  get error() {
    return this._error;
  }

  sendLogToServer = async (level, message) => {
    const payload = {
      level,
      message
    };
    await setLogs(payload);
  };
}
