import fixWebmDuration from 'fix-webm-duration';
import { deleteDB, openDB } from 'idb';
import streamSaver from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill/ponyfill';
import * as recorderActions from './actions/recorderActions';
import * as requestActions from './actions/requestActions';
import * as roomActions from './actions/roomActions';
import Logger from './Logger';
import { store } from './store';
import ElectronServices from './utils/electronServices';
import isElectron from 'is-electron';
import { ALERT_SOUND_TYPE } from './utils/constants';

export default class BrowserRecorder {
  constructor() {
    // react
    this.intl = null;

    // MediaRecorder
    this.recorder = null;
    this.recordingMimeType = null;
    this.recordingData = [];
    this.recorderStream = null;
    this.gdmStream = null;
    this.roomClient = null;
    this.isRemoteRecording = false;
    this.fileName = 'default.webm';
    this.logger = new Logger('Recorder');

    // IndexedDB
    this.idbDB = null;
    this.logToIDB = null;
    this.idbName = 'default';
    this.idbStoreName = 'chunks';

    // MIXER
    this.ctx = null;
    this.dest = null;
    this.gainNode = null;
    this.audioConsumersMap = new Map();
    this.micProducerId = null;
    this.micProducerStreamSource = null;

    this.RECORDING_CONSTRAINTS = {
      preferCurrentTab: true,
      videoBitsPerSecond: 8000000,
      video: {
        displaySurface: 'browser',
        width: { ideal: 1920 },
      },
      audio: false,
      advanced: [
        { width: 1920, height: 1080 },
        { width: 1280, height: 720 },
      ],
    };

    // 10 sec
    this.RECORDING_SLICE_SIZE = 10000;
  }

  mixer(audiotrack, videostream) {
    // AUDIO
    if (audiotrack != null) {
      this.ctx
        .createMediaStreamSource(new MediaStream([audiotrack]))
        .connect(this.dest);
    }
    // VIDEO+AUDIO
    if (videostream.getAudioTracks().length > 0) {
      this.ctx.createMediaStreamSource(videostream).connect(this.dest);
    }
    // VIDEOMIX
    let tracks = this.dest.stream.getTracks();

    tracks = tracks.concat(videostream.getVideoTracks());

    return new MediaStream(tracks);
  }

  async startLocalRecording({
    roomClient,
    additionalAudioTracks,
    recordingMimeType,
    roomname,
  }) {
    this.roomClient = roomClient;
    this.recordingMimeType = recordingMimeType;
    this.logger.debug('startLocalRecording()');

    this.ctx = new AudioContext();
    this.dest = this.ctx.createMediaStreamDestination();
    this.gainNode = this.ctx.createGain();
    this.gainNode.connect(this.dest);

    // Check
    if (typeof MediaRecorder === "undefined") {
      throw new Error('Unsupported media recording API');
    }
    // Check mimetype is supported by the browser
    if (MediaRecorder.isTypeSupported(this.recordingMimeType) === false) {
      throw new Error(
        'Unsupported media recording format %O',
        this.recordingMimeType
      );
    }
    const recordingAlreadyStarted = "Recording is already started"
    try {
      // Screensharing
      if(isElectron()){
        const source = await ElectronServices.getDesktopCaptureSource();
        this.gdmStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: source,
              minWidth: 1280,
              maxWidth: 1280,
              minHeight: 720,
              maxHeight: 720
            }
          }
      }); 
      } else {
        this.gdmStream = await navigator.mediaDevices.getDisplayMedia(
          this.RECORDING_CONSTRAINTS
        );
      }

      if(this.isRemoteRecording){
        throw new Error(recordingAlreadyStarted);
      }

      this.gdmStream.getVideoTracks().forEach((track) => {
        track.addEventListener('ended', (e) => {
          this.logger.debug(
            `gdmStream ${track.kind} track ended event: ${JSON.stringify(e)}`
          );
          this.stopLocalRecording();
        });
      });

      store.dispatch(recorderActions.setLocalRecordingState('start'));

      if (additionalAudioTracks.length > 0) {
        // add mic track
        this.recorderStream = this.mixer(
          additionalAudioTracks[0],
          this.gdmStream
        );
        // add other audio tracks
        for (let i = 1; i < additionalAudioTracks.length; i++) {
          this.addTrack(additionalAudioTracks[i]);
        }
      } else {
        this.recorderStream = this.mixer(null, this.gdmStream);
      }
      const dt = new Date();
      const year = dt.getFullYear();
      const month = `0${dt.getMonth() + 1}`.slice(-2);
      const day = `0${dt.getDate()}`.slice(-2);
      const hours = dt.getHours();
      const minutes = `0${dt.getMinutes()}`.slice(-2);
      const seconds = dt.getSeconds();

      const rdt = `${year}-${month}-${day}_${hours}_${minutes}_${seconds}`;

      this.recorder = new MediaRecorder(this.recorderStream, {
            mimeType: this.recordingMimeType,
      });


      const ext = this.recorder.mimeType.split(';')[0].split('/')[1];

      this.fileName = `${roomname}-recording-${rdt}.${ext}`;

      if (
        typeof indexedDB === 'undefined' ||
        typeof indexedDB.open === 'undefined'
      ) {
        this.logger.warn(
          'IndexedDB API is not available in this browser. Fallback to '
        );
        this.logToIDB = false;
      } else {
        this.idbName = Date.now();
        const idbStoreName = this.idbStoreName;

        this.idbDB = await openDB(this.idbName, 1, {
          upgrade(db) {
            db.createObjectStore(idbStoreName);
          },
        });
      }

      let chunkCounter = 0;

      // Save a recorded chunk (blob) to indexedDB
      const saveToDB = async (data) => {
        return await this.idbDB.put(this.idbStoreName, data, Date.now());
      };

      if (this.recorder) {
        let startTime, pauseStartTime;
        this.recorder.onstart = () => {
          startTime = Date.now();
          roomClient._soundNotification(ALERT_SOUND_TYPE.RECORD_IN_PROGRESS);
        };
        this.recorder.onpause = () => {
          pauseStartTime = Date.now();
        };
        this.recorder.onresume = () => {
          startTime = startTime + Date.now() - pauseStartTime;
          pauseStartTime = null;
        };
        this.recorder.ondataavailable = (e) => {
          if (e.data && e.data.size > 0) {
            chunkCounter++;
            this.logger.debug(`put chunk: ${chunkCounter}`);
            if (this.logToIDB) {
              try {
                saveToDB(e.data);
              } catch (error) {
                this.logger.error(
                  'Error during saving data chunk to IndexedDB! error:%O',
                  error
                );
              }
            } else {
              this.recordingData.push(e.data);
            }
          }
        };

        this.recorder.onerror = (error) => {
          this.logger.error(`Recorder onerror: ${error}`);
          switch (error.name) {
            case 'SecurityError':
              store.dispatch(
                requestActions.notify({
                  type: 'error',
                  text: this.intl.formatMessage({
                    id: 'room.localRecordingSecurityError',
                    defaultMessage:
                      'Recording the specified source is not allowed due to security restrictions. Check you client settings!',
                  }),
                })
              );
              break;
            case 'InvalidStateError':
            default:
              throw new Error(error);
          }
        };

        this.recorder.onstop = (e) => {
          this.logger.debug(`Logger stopped event: ${e}`);
          if (pauseStartTime) {
            startTime = startTime + Date.now() - pauseStartTime;
            pauseStartTime = null;
          }

          const recordDuration = Date.now() - startTime;

          if (this.logToIDB) {
            try {
              const useFallback = false;

              if (useFallback) {
                this.idbDB.getAll(this.idbStoreName).then((blobs) => {
                  this.saveRecordingAndCleanup(
                    blobs,
                    this.idbDB,
                    this.idbName,
                    recordDuration
                  );
                });
              } else {
                // stream saver
                // On firefox WritableStream isn't implemented yet web-streams-polyfill/ponyfill will fix it
                if (!window.WritableStream) {
                  streamSaver.WritableStream = WritableStream;
                }
                const fileStream = streamSaver.createWriteStream(
                  `${this.idbName}.webm`,
                  {
                    // size : blob.size // Makes the procentage visiable in the download
                  }
                );

                const writer = fileStream.getWriter();

                this.idbDB.getAllKeys(this.idbStoreName).then((keys) => {
                  // recursive function to save the data from the indexed db
                  this.saveRecordingWithStreamSaver(
                    keys,
                    writer,
                    this.idbDB,
                    this.idbName,
                    true,
                  );
                });
              }
            } catch (error) {
              this.logger.error(
                'Error during getting all data chunks from IndexedDB! error: %O',
                error
              );
            }
          } else {
            this.saveRecordingAndCleanup(
              this.recordingData,
              this.idbDB,
              this.idbName,
              recordDuration
            );
          }
        };

        this.recorder.start(this.RECORDING_SLICE_SIZE);
        // abort so it dose not look stuck

        window.onbeforeunload = () => {
          if (this.recorder !== null) {
            this.stopLocalRecording();

          }
        };
        recorderActions.setLocalRecordingState('start');
      }
    } catch (error) {
      

      if(error.message === recordingAlreadyStarted){
        store.dispatch(
          requestActions.notify({
            type: 'error',
            text: this.intl.formatMessage({
              id: 'room.unexpectedErrorDuringLocalRecording',
              defaultMessage: 'Recording is already started, please wait for the other user to stop the recording.',
            }),
          })
        );
      }

      this.logger.error('startLocalRecording() [error:"%o"]', error);

      if (this.recorder) this.recorder.stop();
      store.dispatch(roomActions.setRecordingOption({ recordingType: "" }));
      store.dispatch(recorderActions.setLocalRecordingState('stop'));
      store.dispatch(roomActions.setRecordingOption({ recordingType: "" }));
      if (
        typeof this.gdmStream !== 'undefined' &&
        this.gdmStream &&
        typeof this.gdmStream.getTracks === 'function'
      ) {
        this.gdmStream.getTracks().forEach((track) => track.stop());
      }

      this.gdmStream = null;
      this.recorderStream = null;
      this.recorder = null;

      return -1;
    }

    try {
      await this.roomClient.sendRequest('setLocalRecording', {
        localRecordingState: 'start',
      });

      store.dispatch(
        requestActions.notify({
          text: this.intl.formatMessage({
            id: 'room.youStartedLocalRecording',
            defaultMessage: 'You started recording.',
          }),
        })
      );
    } catch (error) {
      store.dispatch(
        requestActions.notify({
          type: 'error',
          text: this.intl.formatMessage({
            id: 'room.unexpectedErrorDuringLocalRecording',
            defaultMessage: 'Unexpected error ocurred during recording.',
          }),
        })
      );
      this.logger.error('startLocalRecording() [error:"%o"]', error);
    }
  }
  // eslint-disable-next-line no-unused-vars
  async stopLocalRecording(stopCallFrom) {
    this.logger.debug('stopLocalRecording()');
    try {
      this.recorder.stop();
      if (stopCallFrom !== 'stoppedByDisconnectEvent') {
        store.dispatch(
          requestActions.notify({
            text: this.intl.formatMessage({
              id: 'room.youStoppedLocalRecording',
              defaultMessage: 'You stopped recording.',
            }),
          })
        );
      }

      store.dispatch(recorderActions.setLocalRecordingState('stop'));
      store.dispatch(roomActions.setRecordingOption({ recordingType: "" }));
      await this.roomClient.sendRequest('setLocalRecording', {
        localRecordingState: 'stop',
      });
    } catch (error) {
      
      this.logger.error('stopLocalRecording() [error:"%o"]', error);
    }
  }
  async pauseLocalRecording() {
    this.recorder.pause();
    store.dispatch(recorderActions.setLocalRecordingState('pause'));
    await this.roomClient.sendRequest('setLocalRecording', {
      localRecordingState: 'pause',
    });
  }
  async resumeLocalRecording() {
    this.recorder.resume();
    store.dispatch(recorderActions.setLocalRecordingState('resume'));
    await this.roomClient.sendRequest('setLocalRecording', {
      localRecordingState: 'resume',
    });
  }
  invokeSaveAsDialog(blob) {
    const link = document.createElement('a');

    link.style = 'display:none;opacity:0;color:transparent;';
    link.href = URL.createObjectURL(blob);
    link.download = this.fileName;

    (document.body || document.documentElement).appendChild(link);
    if (typeof link.click === 'function') {
      link.click();
    } else {
      link.target = '_blank';
      link.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(link.href);
  }
  // save recording and destroy
  async saveRecordingAndCleanup(blobs, db, dbName, duration) {
    // merge blob
    const oldBlob = new Blob(blobs, { type: this.recordingMimeType });

    const blob = await fixWebmDuration(oldBlob, duration, { logger: false });

    // Stop all used video/audio tracks
    if (this.recorderStream && this.recorderStream.getTracks().length > 0)
      this.recorderStream.getTracks().forEach((track) => track.stop());

    if (this.gdmStream && this.gdmStream.getTracks().length > 0)
      this.gdmStream.getTracks().forEach((track) => track.stop());

    // save as
    this.invokeSaveAsDialog(blob, `${dbName}.webm`);

    // destroy
    this.saveRecordingCleanup(db, dbName);
  }

  // save recording with Stream saver and destroy
  saveRecordingWithStreamSaver(keys, writer, db, dbName,stop = false) {
    let readableStream = null;

    let reader = null;

    let pump = null;

    const key = keys[0];

    // on the first call we stop the streams (tab/screen sharing)
    if (stop) {
      // Stop all used video/audio tracks
      if (this.recorderStream && this.recorderStream.getTracks().length > 0)
        this.recorderStream.getTracks().forEach((track) => track.stop());

      if (this.gdmStream && this.gdmStream.getTracks().length > 0)
        this.gdmStream.getTracks().forEach((track) => track.stop());
    }
    // we remove the key that we are removing
    keys.shift();
    db.get(this.idbStoreName, key).then((blob) => {
      if (keys.length === 0) {
        // if this is the last key we close the writable stream and cleanup the indexedDB
        readableStream = blob.stream();
        reader = readableStream.getReader();
        pump = () =>
          reader
            .read()
            .then((res) =>
              res.done
                ? this.saveRecordingCleanup(db, dbName, writer)
                : writer.write(res.value).then(pump)
            );
        pump();
      } else {
        // push data to the writable stream
        readableStream = blob.stream();
        reader = readableStream.getReader();
        pump = () =>
          reader
            .read()
            .then((res) =>
              res.done
                ? this.saveRecordingWithStreamSaver(
                    keys,
                    writer,
                    db,
                    dbName,
                    false,
                  )
                : writer.write(res.value).then(pump)
            );
        pump();
      }
    });
  }

  saveRecordingCleanup(db, dbName, writer = null) {
    if (writer != null) {
      writer.close();
    }
    // destroy
    db.close();
    deleteDB(dbName);
    // delete all previouse recordings that might be left in indexedDB
    // https://bugzilla.mozilla.org/show_bug.cgi?id=934640
    if (indexedDB.databases instanceof Function) {
      indexedDB
        .databases()
        .then((r) => r.forEach((dbdata) => deleteDB(dbdata.name)));
    }

    this.recordingMimeType = null;
    this.recordingData = [];
    this.recorder = null;
    this.ctx.close();
  }

  recoverRecording(dbName) {
    try {
      openDB(dbName, 1).then((db) => {
        db.getAll(this.idbStoreName).then((blobs) => {
          this.saveRecordingAndCleanup(blobs, db, dbName);
        });
      });
    } catch (error) {
      this.logger.error(
        'Error during save recovered recording error: %O',
        error
      );
    }
  }

  checkMicProducer(producers) {
    // is it already appended to stream?
    if (
      this.recorder != null &&
      (this.recorder.state === 'recording' || this.recorder.state === 'paused')
    ) {
      const micProducer = Object.values(producers).find(
        (p) => p.kind === 'audio'
      );

      if (micProducer && this.micProducerId !== micProducer.id) {
        // delete/dc previous one
        if (this.micProducerStreamSource) {
          this.micProducerStreamSource.disconnect(this.dest);
        }

        this.micProducerStreamSource = this.ctx.createMediaStreamSource(
          new MediaStream([micProducer.track])
        );
        this.micProducerStreamSource.connect(this.dest);

        // set Mic id
        this.micProducerId = micProducer.id;
      }
    }
  }
  checkAudioConsumer(consumers, isRemoteRecording) {

    this.isRemoteRecording = isRemoteRecording

    if (
      this.recorder != null &&
      (this.recorder.state === 'recording' || this.recorder.state === 'paused')
    ) {
      const audioConsumers = Object.values(consumers).filter(
        (p) => p.kind === 'audio'
      );

      for (let i = 0; i < audioConsumers.length; i++) {
        if (!this.audioConsumersMap.has(audioConsumers[i].id)) {
          const audioConsumerStreamSource = this.ctx.createMediaStreamSource(
            new MediaStream([audioConsumers[i].track])
          );

          audioConsumerStreamSource.connect(this.dest);
          this.audioConsumersMap.set(
            audioConsumers[i].id,
            audioConsumerStreamSource
          );
        }
      }

      for (const [
        consumerId,
        aCStreamSource,
      ] of this.audioConsumersMap.entries()) {
        if (!audioConsumers.find((c) => consumerId === c.id)) {
          aCStreamSource.disconnect(this.dest);
          this.audioConsumersMap.delete(consumerId);
        }
      }
    } else if (this.audioConsumersMap.size > 0) { 
      
        for (const [
          consumerId,
          aCStreamSource,
        ] of this.audioConsumersMap.entries()) {  
            aCStreamSource.disconnect(this.dest);
            this.audioConsumersMap.delete(consumerId);
       
          }   
    }
  }
}
export const recorder = new BrowserRecorder();
