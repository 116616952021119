const initialState =
{
	toolAreaOpen   : false,
	currentToolTab : 'chat', // chat, settings, users
	unreadMessages : 0,
	unreadFiles    : 0
};

const toolarea = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'TOGGLE_TOOL_AREA':
		{
			if(state.currentToolTab !== action.payload.toolTab){
				let toolAreaOpen = true;
				if(state.currentToolTab === "files" && action.payload.toolTab === 'chat'){
					toolAreaOpen =false;
				}
				const unreadMessages = toolAreaOpen && state.currentToolTab === 'chat' ? 0 : state.unreadMessages;
				const unreadFiles = toolAreaOpen && state.currentToolTab === 'chat' ? 0 : state.unreadFiles;

				return { ...state, toolAreaOpen, unreadMessages, unreadFiles };
			}
	
			const toolAreaOpen = !state.toolAreaOpen;
			const unreadMessages = toolAreaOpen && state.currentToolTab === 'chat' ? 0 : state.unreadMessages;
			const unreadFiles = toolAreaOpen && state.currentToolTab === 'chat' ? 0 : state.unreadFiles;

			return { ...state, toolAreaOpen, unreadMessages, unreadFiles };
		}

		case 'OPEN_TOOL_AREA':
		{
			const toolAreaOpen = true;
			const unreadMessages = state.currentToolTab === 'chat' ? 0 : state.unreadMessages;
			const unreadFiles = state.currentToolTab === 'chat' ? 0 : state.unreadFiles;

			return { ...state, toolAreaOpen, unreadMessages, unreadFiles };
		}

		case 'CLOSE_TOOL_AREA':
		{
			const toolAreaOpen = false;

			return { ...state, toolAreaOpen };
		}

		case 'SET_TOOL_TAB':
		{
			const { toolTab } = action.payload;
			const unreadMessages = toolTab === 'chat' ? 0 : state.unreadMessages;
			const unreadFiles = toolTab === 'chat' ? 0 : state.unreadFiles;

			return { ...state, currentToolTab: toolTab, unreadMessages, unreadFiles };
		}

		case 'ADD_NEW_RESPONSE_MESSAGE':
		{
			if (state.toolAreaOpen && state.currentToolTab === 'chat')
			{
				return state;
			}

			return { ...state, unreadMessages: state.unreadMessages + 1 };
		}

		case 'ADD_FILE':
		{
			if (state.toolAreaOpen && state.currentToolTab === 'chat')
			{
				return state;
			}

			return { ...state, unreadFiles: state.unreadFiles + 1 };
		}

		case 'SET_TOTAL_UNREAD_MESSAGE_COUNT':
		{   
			if (state.toolAreaOpen && state.currentToolTab === 'chat')
			{
				return state;
			}

			return { ...state, unreadMessages: action.payload.totalUnreadMessageCount };
		}

		case 'SET_TOTAL_UNREAD_FILE_COUNT':
		{   
			if (state.toolAreaOpen && state.currentToolTab === 'chat')
			{
				return state;
			}

			return { ...state, unreadFiles: action.payload.totalUnreadFileCount };
		}

		case 'RE_INITIALIZED_ROOM':
      	{
        	return { ...initialState  };
      	}

		default:
			return state;
	}
};

export default toolarea;
