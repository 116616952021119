import { scrollToMessageId } from '../utils/utils';
import
{
	createNewMessage
} from './helper';

const chat = (state = [], action) =>
{
	switch (action.type)
	{
		case 'ADD_NEW_USER_MESSAGE':
		{
			const { text, messageId, peerId } = action.payload;

			const message = createNewMessage(text, 'client', 'Me', undefined, messageId, peerId);

			
			setTimeout(() => {
				// scroll to new message
				scrollToMessageId(null, messageId); 
			}, 10);

			return [ ...state, message ];
		}

		case 'ADD_NEW_RESPONSE_MESSAGE':
		{
			const { message } = action.payload;

			return [ ...state, message ];
		}

		case 'ADD_CHAT_HISTORY':
		{
			const { chatHistory } = action.payload;

			return [ ...state, ...chatHistory ];
		}

		case 'RE_INITIALIZED_ROOM':
		{
			return [];
		}

		case 'ADD_MESSAGES_WITH_CHANGED_DISPLAY_NAME':
		{
			const { displayName, peerId } = action.payload
			const messages = state.length > 0 && state.map((message)=>{
				if(message.peerId === peerId){
					message.name = displayName;
				}
				return message
			})

			const lastmessage= messages.length > 0 && messages.slice(-1)[0];
			
			return messages.length > 0 ? [ ...state, lastmessage ] : state;			
		}

		default:
			return state;
	}
};

export default chat;
