import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIPInfo } from '../../../utils/utils';
import * as settingsActions from '../../../actions/settingsActions';

const HeaderUtility = () => {
  const dispatch = useDispatch();
  const ipInfo = useSelector((state) => state.settings.ipInfo);

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const info = await getIPInfo();
        dispatch(settingsActions.fetchIPInfo(info));
      } catch (error) {
        console.error('Failed to fetch IP address:', error);
      }
    };

    if (!ipInfo) {
      fetchIPAddress();
    }
  }, [dispatch, ipInfo]);

  return ipInfo;
};

export default HeaderUtility;