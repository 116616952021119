import React, { useEffect, useState,useRef } from "react";
import {
  Box,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
  Button,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, Link  } from "react-router-dom";
import PropTypes from "prop-types";
import * as settingsActions from "../../../actions/settingsActions";
import * as roomActions from "../../../actions/roomActions";
//import * as yup from 'yup';
import { withRoomContext } from "../../../RoomContext";
import { Alert } from "@material-ui/lab";
import {
  generateExtraData,
  generateDisplayName,
  generateRandomString,
  makeRequest,
} from "../../../utils/utils";
import Loading from "../../../components/Loading";
//import { ERROR_MESSAGE, SPECIAL_CHAR_PATTERN } from "../../../utils/constants";
import { JoinFormSchema } from "./JoinFormValidationSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ENDPOINT, ERROR_MESSAGE, METHOD_TYPES } from "../../../utils/constants";
import useDebounce from "../../../Hooks/useDebounce";
import { GTM_EVENT, GTM_METHODS, sendAnalyticsEvent } from "../../../utils/gtmUtils";

/**
 * The JoinForm component is a form that allows users to enter a room name and their display name, and
 * then join a meeting.
 * @returns The JoinForm component is being returned.
 */

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.extraDark,
    textDecoration:"underline",
    fontFamily:theme.typography.fontFamily.medium,
    fontWeight:500,
    [theme.breakpoints.down("450")]: {
      fontSize: "14px",
    },
  },

  checkBox: {
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap:"nowrap",
    },
    [theme.breakpoints.up("1730")]: {
      "& .MuiFormGroup-root": {
        alignItems:"center"
      },
    },
    [theme.breakpoints.down("1280")]: {
      "& .MuiFormGroup-root": {
        alignItems:"center"
      },
    },
    [theme.breakpoints.down("480")]: {
      "& .MuiFormGroup-root": {
        alignItems:"flex-start"
      },
    },
    '& p:last-child':{
      marginInline: '35px !important'
    }
  },
  label: {
    fontFamily:theme.typography.fontFamily.medium,
    fontWeight:500,
    fontSize: "14px",
  },
  checkBoxLabel: {
    maxHeight: "26px",
    maxWidth: "30px",
    color: '#000',
    "& .MuiIconButton-root": {
      background: "transparent",
      border: "none",
      minWidth: "36px"
    },
    "& .MuiIconButton-label": {
      color: `${theme.common.grayBorder} !important`  
    },
    "& .Mui-checked": {
      "& .MuiIconButton-label": {
        color: `${theme.palette.primary.extraDark} !important`  
      }
    },
  },
  blueHomeBtn: {
    background: theme.palette.primary.extraDark,
  },
  lobbyMsg: {
    color: theme.palette.success.main,
    fontWeight: "bold",
  },
  snackbarMessage: {
    fontSize: "14px",
    alignItems: "center",
    maxWidth: "420px",
    "& button": {
      minWidth: "22px !important",
      minHeight: "22px !important",
      border: "1px solid transparent",
      background: "none",
      "& svg": {
        color: theme.common.black,
      },
      "&:hover": {
        background: "none",
      },
    },
    [theme.breakpoints.down("450")]: {
      width: "300px",
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "14px",
    margin: "4px 14px 0"
  },
  inputBorder: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 52,
      border: `1px solid ${theme.common.lightGrayBorder}`,
      borderRadius: "44px",
      background:theme.common.lightGrey,
      [theme.breakpoints.up("sm")]:{
        marginBottom:0
      },
      '& .MuiOutlinedInput-input:-webkit-autofill' : {
        "box-shadow" : "0 0 0 1000px #ffffff inset !important",
        "-webkit-text-fill-color": "#000 !important"
      },
      '& .MuiOutlinedInput-input':{
        fontSize:18,
      }
    },
    [theme.breakpoints.down("450")]: {
      margin: "auto",
    },
  },
}));

const JoinForm = ({
  roomClient,
  room,
  roomId,
  displayName,
  changeDisplayName,
  setJoiningRoom,
  joiningRoom,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const history = useHistory();
  const roomNotStarted = useSelector(state => state.room.roomNotStarted);
  const ipInfo = useSelector(state => state.settings.ipInfo);
  const [isInternetConnected, setIsInternetConnected] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [emailError, setEmailError] = useState(null);
  const joinFormtheme = useTheme();
  const smallScreen = useMediaQuery(joinFormtheme.breakpoints.down('xs'));
  const roomNewId = useSelector(state => state?.settings?.userData?.roomId)|| ''
  const { register, handleSubmit, watch, formState: { errors, isDirty, isValid } } = useForm({
    resolver: yupResolver(JoinFormSchema),
    mode: "onTouched",
    defaultValues: {
      roomName: roomId || roomNewId,
      fullName: displayName || "",
      email: "",
    }
  });
  
  const email = watch("email", "");
  const watchTerms = watch("terms", false);
  const debouncedValue = useDebounce(email, 500);

  useEffect(() => {
    const checkAvailability = async () => {
      try {
        if (errors.email) {
          return false;
        }
        const response = await makeRequest(
          METHOD_TYPES.POST,
          API_ENDPOINT.CHECK_EMAIL_AVAILIBILITY,
          {email}
        );
        setEmailError(response.message);
      } catch (error) {
        setEmailError(error);
      }
    };

    if (debouncedValue) {
      checkAvailability();
    } else {
      setEmailError("");
    }
    // eslint-disable-next-line
  }, [debouncedValue]);

  useEffect(() => {
    if (joiningRoom) {
      const activeElement = document.activeElement;

      if (activeElement instanceof HTMLElement) {
        activeElement.blur();
      }
    }
  }, [joiningRoom]);

  useEffect(() => {
    const handleOnline = () => {
      setIsInternetConnected(true);
    };

    const handleOffline = () => {
      setIsInternetConnected(false);
    };

    if (navigator.onLine) {
      handleOnline();
    } else {
      handleOffline();
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    !!roomNotStarted && setJoiningRoom(false);
  }, [roomNotStarted, setJoiningRoom]);
 
  const handleinputClick = (e) => {
    if (smallScreen) {
      inputRef.current.scrollIntoView();
      e.target.blur();
      e.target.focus();
    }
  };

  const onSubmit = async (formData) => {
    sendAnalyticsEvent("Call Joined By Guest", GTM_METHODS.BUTTON, GTM_METHODS.CLICK, "Call Joined By Guest")
    setNotificationMessage(null);
    setJoiningRoom(true);
    try {
      const systemInfo = generateExtraData();
      const extraData = {
        ipInfo,
        systemInfo,
        isGuest: true,
      };
      const fullName =
        (formData.fullName
          ? formData.fullName.trim()
          : generateDisplayName()) || generateDisplayName();
      const payload = {
        fullName,
        email: formData.email,
        extraData,
      };
      const response = await makeRequest(
        METHOD_TYPES.POST,
        API_ENDPOINT.GUEST_REGISTER,
        payload
      );
      if (response.data && response.statusCode === 201) {
        const userDetails = response.data;
        dispatch(settingsActions.setUserId(userDetails.userId));
        dispatch(
          settingsActions.setUserData({
            ...userDetails,
            roomId: formData.roomName,
            joinVideo: false,
            isGuest: true
          })
        );
        changeDisplayName(fullName);
        dispatch(settingsActions.setEmail(formData.email));
        await roomClient.join({
          roomId: formData.roomName,
          joinVideo: false,
          peerId: generateRandomString(),
          extraData,
        });
        if (!roomNotStarted) {
          if(roomId){
            history.push(`/${formData.roomName}`);
          }else{
            history.push(`/${formData.roomName}?isGuest=true`);
          }
        //  history.push(`/${formData.roomName}?isGuest=true`);
          

        }
      } else if (response.statusCode === 409 || response.statusCode === 400) {
        setNotificationMessage({
          message: response.message,
          severity: "error",
        });
        setJoiningRoom(false);
      } else {
        setNotificationMessage({
          message: ERROR_MESSAGE.ROOM_CREATION_FAILED,
          severity: "error",
        });
        setJoiningRoom(false);
      }
    } catch (error) {
      setJoiningRoom(false);
      setNotificationMessage({
        message: error.message || ERROR_MESSAGE.UNEXPECTED_ERROR,
        severity: "error",
      });
    }
  };

  return (
    <Box mt={4}>
      {!!roomNotStarted && (
        <Box mb={2} color="error.main">
          As a guest user, you are not allowed to initiate the room.
        </Box>
      )}

      {!!notificationMessage && (
        <Box mb={2}>
          <Alert
            className={classes.snackbarMessage}
            severity={notificationMessage.severity}
            onClose={() => setNotificationMessage(null)}
          >
            {notificationMessage.message}
          </Alert>
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={2}>
          <FormGroup fullWidth ref={inputRef}>
            {/* <FormLabel htmlFor="roomName" >
              Room Name{" "}
              <Box component="span" color="error.main">
                *
              </Box>
            </FormLabel> */}
            <TextField
              id="roomName"
              variant="outlined"
              placeholder="Enter Room Name *"
              name="roomName"
              error={!!errors.roomName}
              inputProps={{ maxLength: 25 }}
              onClick={handleinputClick}
              {...register("roomName")}
              className={classes.inputBorder}
              required
            />
          </FormGroup>
          {errors.roomName && (
            <p className={classes.error}>{errors.roomName.message}</p>
          )}
        </Box>
        <Box mb={3.5}>
          <FormGroup fullWidth>
            {/* <FormLabel htmlFor="fullName">Your Name</FormLabel> */}
            <TextField
              id="fullName"
              variant="outlined"
              placeholder="Enter Your Name"
              name="fullName"
              onClick={handleinputClick}
              error={!!errors.fullName}
              inputProps={{ maxLength: 64 }}
              {...register("fullName")}
              className={classes.inputBorder}
            />
          </FormGroup>
          {errors.fullName && (
            <p className={classes.error}>{errors.fullName.message}</p>
          )}
        </Box>
        <Box mb={3.5} position={"relative"}>
          <FormGroup>
            {/* <FormLabel className={classes.label} htmlFor="email">
              Email
              <Box component="span" color="error.main">
                {" "}
                *
              </Box>
            </FormLabel> */}
            <TextField
              id="email"
              className={classes.inputBorder}
              variant="outlined"
              placeholder="Enter Your Email *"
              name="email"
              type="email"
              onClick={handleinputClick}
              error={!!errors.email}
              {...register("email")}
              required
            />
          </FormGroup>
          {errors.email && (
            <p className={classes.error}>{errors.email.message}</p>
          )}
          {!!!errors.email && !!emailError && (
            <p className={classes.error}>{emailError}</p>
          )}
        </Box>

      
                <Box mb={3}  className={classes.checkBox} >
                  <FormGroup>
                    <FormControlLabel
                    className={`${classes.label} ${classes.checkBoxLabel}`}
                      control={
                        <Checkbox
                          id="terms"
                          {...register("terms")}
                           checked={watchTerms}
                        />
                      }
                    />
                  <Box className={classes.label}>
                  I agree to the <Link to="/terms-of-use" target="_blank" className={classes.link}>Terms & Conditions</Link> and <Link to="/privacy-policy" target="_blank" className={classes.link}>Privacy Policy</Link>
                  <br /><span style={{"font-size": "10px"}}>Welcome! If under 16, join with a parent or guardian's consent.</span></Box>
                   
                  </FormGroup>
                  {errors.terms && <p className={classes.error}>{errors.terms.message}</p>}
                </Box>
              

        {!room.inLobby ? (
          <>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              size="large"
              type="submit"
              disabled={
                joiningRoom || !isInternetConnected || !isDirty || !isValid  || !!emailError
              }
              className={classes.blueHomeBtn}
            >
              Join Meeting
            </Button>
            {joiningRoom ? <Loading /> : null}
          </>
        ) : (
          <Box textAlign="center" mt="-6px">
            <Typography variant="body1" className={classes.lobbyMsg}>
              {room.signInRequired
                ? `The room is empty! You can Log In to start the meeting or wait until the host joins.`
                : `You'll join the meeting as soon as someone lets you in.`}
            </Typography>
          </Box>
        )}
      </form>
    </Box>
  );
};

JoinForm.propTypes = {
  roomClient: PropTypes.any.isRequired,
  room: PropTypes.object.isRequired,
  //displayName: PropTypes.string.isRequired,
  changeDisplayName: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    room: state.room,
    displayName: state.settings.displayName,
    displayNameInProgress: state.me.displayNameInProgress,
    loginEnabled: state.me.loginEnabled,
    loggedIn: state.me.loggedIn,
    myPicture: state.me.picture,
    joiningRoom: state.room.joiningRoom,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeDisplayName: (displayName) => {
      dispatch(settingsActions.setDisplayName(displayName.trim()));
    },
    setJoiningRoom: (value) => {
      dispatch(roomActions.setJoiningRoom(value));
    },
  };
};

export default withRoomContext(
  connect(mapStateToProps, mapDispatchToProps, null, {
    areStatesEqual: (next, prev) => {
      return (
        prev.room.inLobby === next.room.inLobby &&
        prev.room.signInRequired === next.room.signInRequired &&
        prev.settings.displayName === next.settings.displayName &&
        prev.me.displayNameInProgress === next.me.displayNameInProgress &&
        prev.me.loginEnabled === next.me.loginEnabled &&
        prev.me.loggedIn === next.me.loggedIn &&
        prev.me.picture === next.me.picture &&
        prev.room.joiningRoom === next.room.joiningRoom
      );
    },
  })(JoinForm)
);
