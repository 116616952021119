import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { store } from "../../store";
import { Box, Container, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Header from "../shared/Header/Header";
import Footer from "../shared/Footer/Footer";
import Login from "../../modules/Auth/Login/Login";
import Register from "../../modules/Auth/Register/Register";
import JoinForm from "../../modules/Home/JoinForm/JoinForm";
import ForgetPassword from "../../modules/Auth/ForgetPassword/ForgetPassword";
import ModalBox from "../shared/ModalBox/ModalBox";


const useStyles = makeStyles((theme) => ({
  headerGrid: {
    padding: "4px 0",
    width: "100%",
    height: "65px",
    borderBottom: `1px solid ${theme.common.gray90}`,
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0",
      height: "52px",
    },
  },
  footerBg: {
    backgroundColor: theme.palette.black.extraLightSm,
    padding: "1px 0px 2px",
    "& p": {
      color: theme.common.white,
    },
    "& a": {
      color: theme.common.white,
    },
    "& span": {
      color: theme.common.white,
    },
  },
}));



const Routes = ({ component: Component, isAuthenticated,isHeader, isFooter, ...rest }) => {
  const isLoggedIn = !!store.getState()?.settings?.userData?.token || false;
  const roomID = store.getState()?.settings?.userData?.roomId || '';
  const classes = useStyles();

  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    component: <></>,
  });

  const handleClickOpenPopup = (componentName, componentTitle) => {
    setModalState({
      open: true,
      title: componentTitle,
      component: componentModal[componentName],
    });
  };

  const handleClickClosePopup = () => {
    setModalState({
      open: false,
      title: "",
      component: <></>,
    });
  };

  const componentModal = {
    login: (
      <Login
        handleClickClosePopup={handleClickClosePopup}
        handleClickOpenPopup={handleClickOpenPopup}
      />
    ),
    register: (
      <Register
        handleClickClosePopup={handleClickClosePopup}
        handleClickOpenPopup={handleClickOpenPopup}
      />
    ),
    joinRoom: <JoinForm />,
    forgetPassword: (
      <ForgetPassword
        handleClickClosePopup={handleClickClosePopup}
        handleClickOpenPopup={handleClickOpenPopup}
      />
    ),
  };

  return (
  
     <Route
     {...rest}
     render={(props) =>
      <>
       {isHeader && 
            <Box className={clsx(classes.headerGrid)}>
              <Header handleClickOpenPopup={handleClickOpenPopup} />
            </Box>
          }

      {isAuthenticated ? (
       isLoggedIn ? <Component  handleClickOpenPopup={handleClickOpenPopup} {...props} /> : <Redirect to={`/${roomID}`} />
       ) : (
        <> 
       <Component handleClickOpenPopup={handleClickOpenPopup} {...props} />
       {modalState.open && (
        <ModalBox
          modalState={modalState}
          handleClickClosePopup={handleClickClosePopup}
        >
          {modalState.component}
        </ModalBox>
      )}
      </>
       )}
  {isFooter && 
<Box className={classes.footerBg}>
              <Container>
                <Footer />
              </Container>
            </Box>}
    </>
      }
   />
 
  
  )


}
export default Routes;