import React from "react";
import { Route, Redirect } from "react-router-dom";
import { store } from "../../store";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = !!store.getState()?.settings?.userData?.token || false;
  const roomID = store.getState()?.settings?.userData?.roomId || '';
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={`/${roomID}`} />
      }
    />
  );
};

export default AuthenticatedRoute;
