const initialState =
{
	displayName         : null,
	email 				      : null,
	selectedWebcam      : null,
	selectedAudioDevice : null,
	advancedMode        : false,
	resolution          : 'medium', // low, medium, high, veryhigh, ultra
	lastN               : 1000,
	permanentTopBar		  : true,
  facingMode          : "user",
	peerId				      : null,
	autoGainControl     : true,
	echoCancellation    : true,
	noiseSuppression    : true,
	sampleRate          : 48000, //[ 8000, 16000, 24000, 44100, 48000 ]
	channelCount        : 2, // 1, 2 ]
	sampleSize          : 16, //[ 8, 16, 24, 32 ]
	userId				      : null,
	userData			      : null, // for audio & video
  user                : null,
  error               : null,
  isLoading           : false,
  invalidAttempts     : 0,
  ipInfo              : null,
};

const settings = (state = initialState, action) =>
{
	switch (action.type) {
    case "CHANGE_WEBCAM": {
      return { ...state, selectedWebcam: action.payload.deviceId };
    }

    case 'SET_FACING_MODE': {
      return { ...state, facingMode: action.payload };

    }
    
    case "CHANGE_AUDIO_DEVICE": {
      return { ...state, selectedAudioDevice: action.payload.deviceId };
    }

    case "SET_DISPLAY_NAME": {
      const { displayName } = action.payload;

      return { ...state, displayName };
    }

    case "SET_EMAIL": {
      const { email } = action.payload;

      return { ...state, email };
    }

    case "TOGGLE_ADVANCED_MODE": {
      const advancedMode = !state.advancedMode;

      return { ...state, advancedMode };
    }

    case "SET_LAST_N": {
      const { lastN } = action.payload;

      return { ...state, lastN };
    }

    case "TOGGLE_PERMANENT_TOPBAR": {
      const permanentTopBar = !state.permanentTopBar;

      return { ...state, permanentTopBar };
    }

    case "SET_VIDEO_RESOLUTION": {
      const { resolution } = action.payload;

      return { ...state, resolution };
    }

    case "SET_PEER_ID": {
      const { peerId } = action.payload;

      return { ...state, peerId };
    }

    case "SET_USER_ID": {
      const { userId } = action.payload;
      return { ...state, userId };
    }

    case "SET_USER_DATA": {
      const { userData } = action.payload;
      return { ...state, userData };
    }

    case 'INVALID_ATTEMPTS':
      return {
        ...state,
        invalidAttempts: action.payload,
      };

    case 'FETCH_IP_INFO':
      return {
        ...state,
        ipInfo: action.payload,
      };

    default:
      return state;
  }
};

export default settings;
