import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormGroup,
  TextField,
  Typography,
  withWidth
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { generateExtraData } from "../../../utils/utils";
import { ERROR_MESSAGE } from "../../../utils/constants";
import { generateRandomString } from "../../../utils/utils";
import useStyles from "../HomeJoinForm/HomeJoinFormStyles";
import * as settingsActions from "../../../actions/settingsActions";
import * as roomActions from "../../../actions/roomActions";
import { withRoomContext } from "../../../RoomContext";
import { GTM_EVENT, GTM_METHODS, sendAnalyticsEvent } from "../../../utils/gtmUtils";
const HomeJoinForm = ({
  roomClient,
  room,
  roomId,
  displayName,
  changeDisplayName,
  setJoiningRoom,
  joiningRoom,
  width,
  handleClickOpenPopup,
  isLoggedIn,
}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isInternetConnected, setIsInternetConnected] = useState(true);
  const userDetails = useSelector(state => state.settings.userData);
  const ipInfo = useSelector(state => state.settings.ipInfo);
  const schema = Yup.object().shape({
    roomName: Yup.string()
      .required(ERROR_MESSAGE.ROOM_NAME.REQUIRED)
      .min(3, ERROR_MESSAGE.ROOM_NAME.MIN_MAX)
      .max(25, ERROR_MESSAGE.ROOM_NAME.MIN_MAX)
      .matches(/^[A-Za-z0-9_-]*$/, ERROR_MESSAGE.ROOM_NAME.SPECIAL_CHAR),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      roomName: roomId,
    },
    mode: "all",
  });
  
  
  

  useEffect(() => {
    const handleOnline = () => {
      setIsInternetConnected(true);
    };

    const handleOffline = () => {
      setIsInternetConnected(false);
    };

    if (navigator.onLine) {
      handleOnline();
    } else {
      handleOffline();
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const onSubmit = async (data) => {
    try {
      if(!isLoggedIn){
        dispatch(
          settingsActions.setUserData({
            roomId: data.roomName,
            joinVideo: false,
          }))
        handleClickOpenPopup('login', 'Sign in')
      } else {
        sendAnalyticsEvent(GTM_EVENT.CALL_STARTED, GTM_METHODS.BUTTON, GTM_METHODS.CLICK, 'manual')
        setJoiningRoom(true);
        const systemInfo = generateExtraData();
        const extraData = {
          ipInfo,
          systemInfo,
          isGuest: false
        };
        dispatch(
          settingsActions.setUserData({
            ...userDetails,
            roomId: data.roomName,
            joinVideo: false,
          })
        );
        const displayName = `${userDetails?.firstName} ${userDetails?.lastName || ""}`;
        changeDisplayName(displayName);
        dispatch(settingsActions.setEmail(userDetails.email));
        await roomClient.join({
          roomId: data.roomName,
          joinVideo: false,
          peerId: generateRandomString(),
          extraData
        });
        history.push(`/${data.roomName}`);
      }
    
    } catch (err) {
      setJoiningRoom(false);
    }

  };

  return (
    <>
      {joiningRoom ? <Box display="flex" alignItems="center" justifyContent="center" position="fixed" left={0} top={0} right={0} bottom={0} zIndex={9}><CircularProgress /></Box> : null}        
          <form onSubmit={handleSubmit(onSubmit)}>
          <Box position="relative" mt={4} maxWidth={width === "xs" ? "100%" : 700} mx="auto">
          <FormGroup fullWidth>
          <Controller
                      name="roomName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="roomName"
                          variant="outlined"
                          placeholder="Enter Room Name"
                          error={!!errors.roomName}
                          helperText={errors.roomName?.message}
                          inputProps={{ maxLength: 25 }}
                          className={classes.inputBorder}
                         
                         
                        />
                      
                      )}
                    />
                </FormGroup>
                {room.inLobby && 
                ( <Box textAlign="left" mt="6px">
                      <Typography variant="body1" className={classes.lobbyMsg}>
                        {room.signInRequired
                          ? `The room is empty! You can Log In to start the meeting or wait until the host joins.`
                          : `You'll join the meeting as soon as someone lets you in.`}
                      </Typography>
                    </Box>
                     ) }
                
                <Box position={width === "xs" ? "static" :"absolute"} mt={width === "xs" ? "16px" : 0} top={11} right={12}>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{height:52, minWidth:163}}
                    fullWidth
                    disabled={ Object.keys(errors).length > 0 || joiningRoom || !isInternetConnected || room.inLobby}
                    type="submit"
                  >
                    Join Meeting / Create New Meeting
                  </Button>  </Box>  </Box> </form>
 </>
  );
};

HomeJoinForm.propTypes = {
  roomClient: PropTypes.any.isRequired,
  room: PropTypes.object.isRequired,
  displayName: PropTypes.string.isRequired,
  changeDisplayName: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    room: state.room,
    displayName: state.settings.displayName,
    displayNameInProgress: state.me.displayNameInProgress,
    loginEnabled: state.me.loginEnabled,
    loggedIn: state.me.loggedIn,
    myPicture: state.me.picture,
    joiningRoom: state.room.joiningRoom,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeDisplayName: (displayName) => {
      dispatch(settingsActions.setDisplayName(displayName.trim()));
    },
    setJoiningRoom: (value) => {
      dispatch(roomActions.setJoiningRoom(value));
    },
  };
};

export default withRoomContext(
  connect(mapStateToProps, mapDispatchToProps, null, {
    areStatesEqual: (next, prev) => {
      return (
        prev.room.inLobby === next.room.inLobby &&
        prev.room.signInRequired === next.room.signInRequired &&
        prev.settings.displayName === next.settings.displayName &&
        prev.me.displayNameInProgress === next.me.displayNameInProgress &&
        prev.me.loginEnabled === next.me.loginEnabled &&
        prev.me.loggedIn === next.me.loggedIn &&
        prev.me.picture === next.me.picture &&
        prev.room.joiningRoom === next.room.joiningRoom
      );
    },
  })(withWidth()(HomeJoinForm))
);