const InputOverride = {
    MuiInputBase:{  
        root:{
            background: '#fff'
        }
    },
    MuiOutlinedInput:{
        root:{
            borderRadius: '50px',
        },
        notchedOutline:{
            border:'none'
        },
        input:{
            padding: '0 28px',
            fontSize:'16px'
        }
    },
    MuiFormLabel:{
        root:{
            fontSize:'16px',
            marginBottom:'8px',
            fontFamily: `'DM Sans', sans-serif`,
            color:'#010101'
        }
        
    }
}
export default InputOverride