import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import useStyles from "./FooterStyles";
import FooterMenu from "./FooterMenu";
// import logo from "../../../images/talkroom_blue_logo.webp";
import logo from "../../../images/logo.webp";


const Footer = () => {
  const classes = useStyles();
  const aboutLink = [
    {
      name: "Privacy policy",
      linkUrl: "/privacy-policy",
     
    },
    {
      name: "Terms & Conditions",
      linkUrl: "/terms-of-use",
     
    },
    {
      name:"F&Q" ,
      linkUrl: "/faq",

    },
        ];
  return (
    <Box display="flex" my={7} className={classes.footerspacing}>
      <Grid container spacing={3} alignItems="center">
        <Grid item sm={8} md={5} xs={12}>
            <Box className={classes.logo}>
              <img src={logo} alt="Logo" width="100%"/>
            </Box>
            <Typography variant="body1" className={classes.footerDescription}>
            Experience awesome collaborative sessions with TalkRoom's features.
            </Typography>
        </Grid>
        <Grid item sm={1} md={1} xs={12} className={classes.RespFooter}></Grid>
        <Grid item sm={3} md={6} xs={12} className={classes.footerWrapper}>
          <Box>
            {/* <Typography
              variant="h5"
              component="h5"
              className={classes.footerContent}
            >
              About
            </Typography> */}
            <Box className={classes.respFooter}>
              <FooterMenu linkNames={aboutLink} navbarType="footer"/>
              
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
