export const toggleToolArea = (toolTab) =>
	({
		type : 'TOGGLE_TOOL_AREA',
		payload: {toolTab}
	});

export const openToolArea = () =>
	({
		type : 'OPEN_TOOL_AREA'
	});

export const closeToolArea = () =>
	({
		type : 'CLOSE_TOOL_AREA'
	});

export const setToolTab = (toolTab) =>
	({
		type    : 'SET_TOOL_TAB',
		payload : { toolTab }
	});

export const setTotalUnreadMessageCount = (totalUnreadMessageCount) =>
	({
		type    : 'SET_TOTAL_UNREAD_MESSAGE_COUNT',
		payload : { totalUnreadMessageCount }
	});

export const setTotalUnreadFileCount = (totalUnreadFileCount) =>
	({
		type    : 'SET_TOTAL_UNREAD_FILE_COUNT',
		payload : { totalUnreadFileCount }
	});	