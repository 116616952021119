export const GTM_METHODS = {
    BUTTON: "Button",
    CLICK: "Click",
}

export const GTM_EVENT = {
    CONNECT_NOW: "Connect Now",
    SETTINGS: "Settings",
    COPYLINK: "Copy Link",
    LOCKROOM: "Lock Room",
    UNLOCK_ROOM: "Unlock Room",
    WHITEBOARD: "White Board",
    VIDEO: "Start Video",
    AUDIO: "Start Audio",
    STOP_VIDEO: "Stop Video",
    STOP_AUDIO: "Stop Audio",
    LEAVECALL: "Leave Call",
    PARTICIPANTS: "Participants",
    FLIP_CAMERA: "Flip Camera",
    CHAT: "Chat",
    SHARESCREEN: "Share Screen",
    STOP_SHARESCREEN: "Stop Screen",
    RECORD: "Record",
    STOP_RECORD: "Stop Record",
    SPEAKER: "Speaker",
    FULL_SCREEN: "Full Screen",
    MORE: "More",
    STOP_RECORDING: "Stop Recording",
    HIDE_WHITEBOARD: "Hide Whiteboard",
    CLOSE_WHITEBOARD: "Close Whiteboard",
    CALL_ENDS: "Call Ends",
    CLOSE: "Close",
    CREATE_ACCOUNT:'create_account',
    SIGN_IN:'Sign In',
    CONTACT_US:'Contact Us',
    CALL_STARTED:'Call Started'
}

export const sendAnalyticsEvent = ( gTagEvent,category, action, label) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: gTagEvent,
        category: category,
        action: action,
        label: label
      });
    }
  };