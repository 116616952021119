import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import useStyles from './LoaderStyle';

/**
 * Name: Loader
 * Desc: show loader
 * @param {bool} pageLoader
 */
const Loader = ({ pageLoader }) => {
    const classes = useStyles();

    return (
        <Backdrop className={clsx(classes.backdrop, classes.root)} open={pageLoader}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

Loader.defaultProps = {
    pageLoader: false
};

Loader.propTypes = {
    pageLoader: PropTypes.bool
};

export default Loader;
