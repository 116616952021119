import aws from "aws-sdk";
import S3 from "aws-sdk/clients/s3";
import { AWS_REQUEST_TIMEOUT } from "./constants";

const getAwsKeysFromEnv = () => {
  //Required for initialize aws SDK
  return {
    name: process.env.REACT_APP_BUCKET_NAME,
    directoryName: process.env.REACT_APP_BUCKET_DIRECTORY_NAME,
    accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_ACCESS_KEY_ID,
    expires: Number(process.env.REACT_APP_BUCKET_EXPIRES_TIME),
    regin: process.env.REACT_APP_BUCKET_REGIN,
  };
};

aws.config.update({
  region: getAwsKeysFromEnv().regin,
  accessKeyId: getAwsKeysFromEnv().accessKeyId,
  secretAccessKey: getAwsKeysFromEnv().secretAccessKey,
});

export const getS3Bucket = () => {
  const { accessKeyId, secretAccessKey } = getAwsKeysFromEnv();
  const s3 = new S3({
    accessKeyId,
    secretAccessKey,
  });
  return s3;
};

export const uuIdv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const uniqueFileName = (fileName) => {
  const [, ext] = fileName.split(".");
  return `${uuIdv4()}.${ext}`;
};

export const awsUploadFile = async (
  file,
  key,
  contentType,
  callback,
  callbackForRequest = () => {}
) => {
  return new Promise(async (resolve, reject) => {
    const bucketInfo = await getAwsKeysFromEnv();
    aws.config.update({
      accessKeyId: bucketInfo.accessKeyId,
      secretAccessKey: bucketInfo.secretAccessKey,
      httpOptions: {
        timeout: AWS_REQUEST_TIMEOUT,
      },
    });
    const myBucket = new aws.S3({
      params: { Bucket: `${bucketInfo.name}/${bucketInfo.directoryName}` },
      region: bucketInfo.regin,
    });

    const params = {
      Bucket: `${bucketInfo.name}/${bucketInfo.directoryName}`,
      Key: key,
      Body: file,
    };

    const fileUrl = `${bucketInfo.directoryName}/${key}`;

    const request = myBucket
      .putObject(params)
      .on("httpUploadProgress", callback)
      .send(function (err, data) {
        resolve({ ...data, Key: fileUrl, error: err });
      });

    // callback for request to abort the uploading process.
    callbackForRequest(request.request);
  });
};

export const fetchFile = async (key) => {
  const DEFAULT_IMAGE = "";
  if (!key) {
    return DEFAULT_IMAGE;
  }
  const { name, directoryName, expires } = await getAwsKeysFromEnv();

  const s3 = getS3Bucket();
  const url = s3.getSignedUrl("getObject", {
    Bucket: `${name}/${directoryName}`,
    Key: key,
    Expires: 60 * expires,
  });
  return url || DEFAULT_IMAGE;
};

export const noop = (evt) => {
  if (evt) {
  }
};

export const makeImageUrl = async (url) => {
  const { name, directoryName } = await getAwsKeysFromEnv();
  const splittedUrl = url?.split("/");
  const myCustomUrl =
    splittedUrl?.length > 1
      ? url
      : `https://${name}.s3.amazonaws.com/${directoryName}/${url}`;
  return myCustomUrl;
};

export const deleteFile = async (key, callback = noop) => {
  const imgKey = await makeImageUrl(key);
  const imageKey = imgKey.split("/");
  const { name, directoryName } = await getAwsKeysFromEnv();
  const s3 = getS3Bucket();
  s3.deleteObject(
    {
      Bucket: `${name}/${directoryName}`,
      Key: imageKey[imageKey.length - 1],
    },
    callback
  );
};

export const checkFileOnBucket = (key) => {
  return new Promise(async (resolve, reject) => {
    const { name, directoryName } = await getAwsKeysFromEnv();

    const s3 = getS3Bucket();
    s3.headObject(
      {
        Bucket: `${name}/${directoryName}`,
        Key: key,
      },
      function (err) {
        if (err) {
          resolve("NOT_FOUND");
        } else {
          resolve("FOUND");
        }
      }
    );
  });
};
