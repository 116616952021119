import { MuiThemeProvider } from "@material-ui/core/styles";
import debug from "debug";
import domready from "domready";
import isElectron from "is-electron";
import { SnackbarProvider } from "notistack";
import randomString from "random-string";
import React, { Suspense } from "react";
import { render } from "react-dom";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import * as meActions from "./actions/meActions";
import LoadingView from "./components/LoadingView";
import deviceInfo from "./deviceInfo";
import Logger from "./Logger";
import RoomClient from "./RoomClient";
import RoomContext from "./RoomContext";
import * as serviceWorker from "./serviceWorker";
import { persistor, store } from "./store";
import { theme } from "../src/utils/theme";
import messagesChinese from "./translations/cn";
import messagesCzech from "./translations/cs";
import messagesGerman from "./translations/de";
import messagesDanish from "./translations/dk";
import messagesGreek from "./translations/el";
import messagesSpanish from "./translations/es";
import messagesFrench from "./translations/fr";
import messagesCroatian from "./translations/hr";
import messagesHungarian from "./translations/hu";
import messagesItalian from "./translations/it";
import messagesNorwegian from "./translations/nb";
import messagesPolish from "./translations/pl";
import messagesPortuguese from "./translations/pt";
import messagesRomanian from "./translations/ro";
import messagesTurkish from "./translations/tr";
import messagesUkrainian from "./translations/uk";
import GlobalStyles from "./utils/globalStyles";
import { recorder } from "./BrowserRecorder";
import "./index.css";
import App from "./components/App";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import { isDevEnv } from "./utils";
import { GOOGLE_CLIENT_ID } from "./utils/constants";
import AuthenticatedRoute from "./components/hoc/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/hoc/UnauthenticatedRoute";
import { GoogleOAuthProvider } from '@react-oauth/google';
import CommonLayoutRoute from "./components/hoc/CommonLayoutRoute";
import Routes from "./components/hoc/Routes";
const About = React.lazy(() => import("./components/About/About"));
const Faq = React.lazy(() => import("./components/Faq/Faq"));
const Guide = React.lazy(() => import("./components/Guide/Guide"));
const Recordings = React.lazy(() => import("./components/Recordings"));
const PrivacyPolicy = React.lazy(() =>
  import("./components/PrivacyPolicy/PrivacyPolicy")
);
const Home = React.lazy(() => import("./modules/Home/Home"));
const EmailVerification = React.lazy(() =>
  import("./modules/Auth/EmailVerification/EmailVerification")
);
const ChangePassword = React.lazy(() =>
  import("./modules/Auth/ChangePassword/ChangePassword")
);
const UpdateProfile = React.lazy(() =>
  import("./modules/Auth/UpdateProfile/UpdateProfile")
);

const TermsOfUse  = React.lazy(() =>
  import("./components/TermsOfUse/TermsOfUse")
);
const BirthYear  = React.lazy(() =>
  import("./components/BirthYear/BirthYear")
);

const cache = createIntlCache();

const messages = {
  nb: messagesNorwegian,
  de: messagesGerman,
  hu: messagesHungarian,
  pl: messagesPolish,
  dk: messagesDanish,
  fr: messagesFrench,
  el: messagesGreek,
  ro: messagesRomanian,
  pt: messagesPortuguese,
  zh: messagesChinese,
  es: messagesSpanish,
  hr: messagesCroatian,
  cs: messagesCzech,
  it: messagesItalian,
  uk: messagesUkrainian,
  tr: messagesTurkish,
};

const locale = navigator.language.split(/[-_]/)[0]; // language without region code

const intl = createIntl(
  {
    locale,
    messages: messages[locale],
  },
  cache
);

recorder.intl = intl;

if (!isDevEnv()) {
  debug.disable();
  console.log = console.warn = console.error = console.info = function () {};
}

const logger = new Logger();

let roomClient;

RoomClient.init({ store, intl });

let Router;

if (isElectron()) Router = HashRouter;
else Router = BrowserRouter;

domready(() => {
  logger.debug("DOM ready");

  run();
});

function run() {
  logger.debug("run() [environment:%s]", process.env.NODE_ENV);

  const peerId = randomString({ length: 8 }).toLowerCase();
  const urlParser = new URL(window.location);
  const parameters = urlParser.searchParams;

  const accessCode = parameters.get("code");
  const produce = parameters.get("produce") !== "false";
  const useSimulcast = parameters.get("simulcast") === "true";
  const useSharingSimulcast = parameters.get("sharingSimulcast") === "true";
  const forceTcp = parameters.get("forceTcp") === "true";
  const displayName = parameters.get("displayName");
  const muted = parameters.get("muted") === "true";

  // Get current device.
  const device = deviceInfo();

  store.dispatch(
    meActions.setMe({
      peerId,
      loginEnabled: window.config.loginEnabled,
    })
  );

  roomClient = new RoomClient({
    peerId,
    accessCode,
    device,
    useSimulcast,
    useSharingSimulcast,
    produce,
    forceTcp,
    displayName,
    muted,
  });

  global.CLIENT = roomClient;

  render(
    <Provider store={store}>
		 <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <MuiThemeProvider theme={theme}>
        <GlobalStyles />
        <RawIntlProvider value={intl}>
          <PersistGate loading={<LoadingView />} persistor={persistor}>
            <RoomContext.Provider value={roomClient}>
              <SnackbarProvider
                iconVariant={{
                  success: <CheckCircleIcon style={{ display: "none" }} />,
                  error: (
                    <ErrorIcon
                      style={{ fontSize: "20px", marginRight: "8px" }}
                    />
                  ),
                  warning: <WarningIcon style={{ display: "none" }} />,
                  info: <InfoIcon style={{ display: "none" }} />,
                }}
              >
                <Router>
                  <Suspense fallback={<LoadingView />}>
                    <Switch>
                      <Routes exact path="/about-us" component={About} isHeader={true} isFooter={true}/>
                      <Routes exact path="/privacy-policy" component={PrivacyPolicy} isHeader={true} isFooter={true}/>
                      <Routes exact path="/faq" component={Faq} isHeader={true} isFooter={true}/>
                      <Routes exact path="/terms-of-use" component={TermsOfUse} isHeader={true} isFooter={true}/>
                      <Routes exact path="/birth-year" component={BirthYear} isHeader={true} isFooter={true}/>
                      <Routes exact path="/verify-email" isHeader={true} component={EmailVerification} />
                      <Routes exact path="/reset-password" isHeader={true} isFooter={true} component={ChangePassword} />
                      <Route exact path="/guide" component={Guide} />
                      <Route exact path="/" component={Home} />

                      {/* Protected Route */}
                      <Routes exact path="/update-profile" component={UpdateProfile} isHeader={true} isFooter={true} isAuthenticated={true} />
                      <Routes exact path="/change-password" component={ChangePassword} isHeader={true} isFooter={true} isAuthenticated={true}/>
                      <Routes exact path="/recordings" component={Recordings} isHeader={false} isFooter={false} isAuthenticated={true} />
                      <Route exact path="/:id" component={App} />

                      {/* <Route exact path="/" component={Home} /> */}
                   
                      {/* <CommonLayoutRoute exact path="/about-us" component={About}/> */}
                      {/* <CommonLayoutRoute exact path="/privacy-policy" component={PrivacyPolicy}/> */}
                      {/* <CommonLayoutRoute exact  path="/faq" component={Faq} /> */}
                      {/* <Route exact path="/guide" component={Guide} /> */}
                      {/* <Route exact path="/" component={Home} /> */}
                      {/* <CommonLayoutRoute exact  path="/terms-of-use" component={TermsOfUse} /> */}
                      {/* <CommonLayoutRoute exact  path="/birth-year" component={BirthYear} /> */}

                      {/* <Route exact path="/downloads" component={Downloads} /> */}
                      {/* <UnauthenticatedRoute exact path="/verify-email" isHeader={true} component={EmailVerification} /> */}
                      {/* <UnauthenticatedRoute exact path="/reset-password" isHeader={false} component={ChangePassword} /> */}
                      
                      {/* Protected Route */}
                      {/* <AuthenticatedRoute exact path="/update-profile" component={UpdateProfile} /> */}
                      {/* <AuthenticatedRoute exact path="/change-password" component={ChangePassword} /> */}
                      {/* <AuthenticatedRoute exact path="/recordings" component={Recordings} /> */}
                      {/* <Route exact path="/:id" component={App} /> */}
                
                    </Switch>
                  </Suspense>
                </Router>
              </SnackbarProvider>
            </RoomContext.Provider>
          </PersistGate>
        </RawIntlProvider>
      </MuiThemeProvider>
     </GoogleOAuthProvider>
    </Provider>,
    document.getElementById("multiparty-meeting")
  );
}

serviceWorker.unregister();
