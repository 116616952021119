import { createTheme } from "@material-ui/core/styles";
import color from "./color";
import typography from "./typography";
import OverRide from "./Override/Override";

export const theme = createTheme({
  typography,
  ...color,
  ...OverRide
});
