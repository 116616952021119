import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

  dialogBox:{
    "& .MuiDialog-paper": {
      width:"448px",
      borderRadius:16,
      background:theme.palette.white.main,
    },
    "& .MuiDialogContent-root": {
     padding: "32px",
    },
  },

  titleStyle:{
    color: theme.common.neutral100,
    marginBottom: 24,
    [theme.breakpoints.down("450")]: {
      fontSize: "22px",
    },
  },

  closeIcon: {
    zIndex: 99999,
    position: "absolute",
    right:"0",
    background: `unset !important`,
    border: "none",
    "& .MuiSvgIcon-root": {
      color: theme.common.black,
    },
  },
  
  
  
}));

export default useStyles;
