import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footerLinks: {
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      alignItems: "left",
    },
  },
  headerLinks: {
    fontSize:18,
    display: "block",
    color: theme.common.neutral100,
    padding: theme.spacing(1.5, 0),
    fontFamily: theme.typography.fontFamily.medium,

    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "14px",
    },
  },
  footerLink: {
    fontSize: 20,
    display: "block",
    color: theme.common.gary_200,
    padding: theme.spacing(1.5, 0),
    fontFamily: theme.typography.fontFamily.regular,
    fontWeight:'400',

    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "14px",
    },
  },
}));

export default useStyles;
