import React from 'react'
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from "@material-ui/core";
import useStyles from "./ModalBoxStyle";
import CloseIcon from "@material-ui/icons/Close";


 const ModalBox = ({ modalState, children, handleClickClosePopup }) => {

const classes = useStyles();

  return (
    <Dialog open={modalState.open} onClose={handleClickClosePopup} className={classes.dialogBox} >
      <IconButton
        aria-label="close"
        size="small"
        className={classes.closeIcon}
        onClick={handleClickClosePopup }
        title="Close"
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>

     {children && (
       <DialogContent>
        {modalState.title && ( <Typography variant='h4' component='h4' className={classes.titleStyle} >
                {modalState.title}
              </Typography> )}
       {children}
        </DialogContent>
     )}
     
    </Dialog>
  )
}

export default ModalBox;
