import isElectron from "is-electron";
const electron = isElectron() && window.require("electron");

export default class ElectronServices {

  static async getDesktopCaptureSource() {
    const res = await electron.ipcRenderer.invoke("sourceInfo");

    return res;
  }

  static async openElectronWindow(url) {
    await electron.ipcRenderer.send("openElectronWindow", url);
    return;
  }

  static async isRoomJoined(isRoomJoined) {
    await electron.ipcRenderer.send("isRoomJoined", isRoomJoined);
    return;
  }
}
