import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { generateRandomString } from "../../utils/utils";
import { store } from "../../store";
import Header from "../shared/Header/Header";
import Login from "../../modules/Auth/Login/Login";
import Register from "../../modules/Auth/Register/Register";
import JoinForm from "../../modules/Home/JoinForm/JoinForm";
import ForgetPassword from "../../modules/Auth/ForgetPassword/ForgetPassword";
import ModalBox from "../shared/ModalBox/ModalBox";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  headerGrid: {
    padding: "4px 0",
    width: "100%",
    height: "65px",
    borderBottom: `1px solid ${theme.common.gray90}`,
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0",
      height: "52px",
    },
  },
  footerBg: {
    backgroundColor: theme.palette.black.extraLightSm,
    padding: "1px 0px 2px",
    "& p": {
      color: theme.common.white,
    },
    "& a": {
      color: theme.common.white,
    },
    "& span": {
      color: theme.common.white,
    },
  },
}));

const UnauthenticatedRoute = ({ component: Component, isHeader, ...rest }) => {
  const isLoggedIn = !!store.getState()?.settings?.userData?.token || false;
  const roomID =
    store.getState()?.settings?.userData?.roomId || generateRandomString();
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    component: <></>,
  });

  const handleClickOpenPopup = (componentName, componentTitle) => {
    setModalState({
      open: true,
      title: componentTitle,
      component: componentModal[componentName],
    });
  };

  const handleClickClosePopup = () => {
    setModalState({
      open: false,
      title: "",
      component: <></>,
    });
  };

  const componentModal = {
    login: (
      <Login
        handleClickClosePopup={handleClickClosePopup}
        handleClickOpenPopup={handleClickOpenPopup}
      />
    ),
    register: (
      <Register
        handleClickClosePopup={handleClickClosePopup}
        handleClickOpenPopup={handleClickOpenPopup}
      />
    ),
    joinRoom: <JoinForm />,
    forgetPassword: (
      <ForgetPassword
        handleClickClosePopup={handleClickClosePopup}
        handleClickOpenPopup={handleClickOpenPopup}
      />
    ),
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        !!!isLoggedIn ? (
          <>
          {isHeader && 
            <Box className={clsx(classes.headerGrid)}>
              <Header handleClickOpenPopup={handleClickOpenPopup} />
            </Box>
          }
            
            <Component handleClickOpenPopup={handleClickOpenPopup} {...props} />
            {modalState.open && (
              <ModalBox
                modalState={modalState}
                handleClickClosePopup={handleClickClosePopup}
              >
                {modalState.component}
              </ModalBox>
            )}
          </>
        ) : (
          <Redirect to={`/${roomID}`} />
        )
      }
    />
  );
};

export default UnauthenticatedRoute;
