export const addUserMessage = (text, messageId, peerId) =>
	({
		type    : 'ADD_NEW_USER_MESSAGE',
		payload : { text, messageId, peerId }
	});

export const addResponseMessage = (message) =>
	({
		type    : 'ADD_NEW_RESPONSE_MESSAGE',
		payload : { message }
	});
	
export const addChatHistory = (chatHistory) =>
	({
		type    : 'ADD_CHAT_HISTORY',
		payload : { chatHistory }
	});

export const addMessagesWithChangedDisplayName = (displayName, peerId) =>
	({
		type 	: 'ADD_MESSAGES_WITH_CHANGED_DISPLAY_NAME',
		payload	: { displayName, peerId }
	})
