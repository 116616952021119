export default {
  useNextVariants: true,
  fontSize: 16,
  h1: {
    fontSize: 72,
    fontWeight:'bold',
    fontFamily:`"Avenir Heavy",  sans-serif`,
    letterSpacing:'-1px'
  },
  h2: {
    fontSize: 56,
    fontWeight:'bold',
    fontFamily:`"Avenir Heavy",  sans-serif`,
    letterSpacing:'-1px'
  },
  h3: {
    fontSize: 44,
    fontWeight:'bold',
    fontFamily: `"Avenir Heavy",  sans-serif`,
    letterSpacing:'-1px'
  },
  h4: {
    fontSize: 32,
    fontFamily: `"Avenir Heavy",  sans-serif`,
    letterSpacing:'-1px',
    fontWeight:'bold',
  },
  h5: {
    fontSize: 18,
    fontWeight:'bold',
    fontFamily:`"Avenir Heavy",  sans-serif`,
  },
  h6: {
    fontSize: 16,
    fontWeight:'bold',
    fontFamily: `'DM Sans', sans-serif`,
  },
  subtitle1:{
    fontSize:20,
    fontFamily:`"Avenir Medium",  sans-serif`,
    letterSpacing:'-1px',
    fontWeight:'500'
  },
  subtitle2:{
    fontSize:24,
    fontFamily: `'DM Sans', sans-serif`,
  },
  body1: {
    fontSize: 14,
    fontFamily: `'DM Sans', sans-serif`,
  },
  body2: {
    fontSize: 12,
    fontFamily: `'DM Sans', sans-serif`,
  },
  body3: {
    fontSize: 10,
    fontFamily: `'DM Sans', sans-serif`,
  },
  fontFamily:{
    bold:`"Avenir Heavy",  sans-serif`,
    medium:`"Avenir Medium",  sans-serif`,
    regular:`"AvenirNextCyr-Regular", sans-serif`
  },
  fontWeight:{
    bold:900,
    normal:400,
    medium:500,
    light:300
  }
};
